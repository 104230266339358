import { graphql } from '../gql';

export const clearCartMutationDocument = graphql(`
  mutation clearCart {
    removeAllOrderLines {
      __typename

      ... on Order {
        id
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`);

export const addProductToCartMutationDocument = graphql(`
  mutation AddItemToOrder($productVariantId: ID!, $quantity: Int!) {
    addItemToOrder(productVariantId: $productVariantId, quantity: $quantity) {
      __typename
      ... on Order {
        id
        code
      }
      ... on ErrorResult {
        errorCode
        message
      }
      ... on InsufficientStockError {
        quantityAvailable
      }
    }
  }
`);
