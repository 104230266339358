import { Container, SimpleGrid } from "@mantine/core";
import ContactDetailsFormContainer from "../components/ContactDetailsForm/ContactDetailsForm.container";
import CheckoutSummaryContainer from "../components/CheckoutSummary/CheckoutSummary.container";
import CheckoutStepper from "../components/CheckoutStepper/CheckoutStepper";
import { CheckoutStep } from "../components/CheckoutStepper/CheckoutStep";

const ContactDetailsPage = () => (
  <Container size={900} my={40}>
    <CheckoutStepper active={CheckoutStep.CONTACT_DETAILS} />
    <Container size={800} my={40}>
      <SimpleGrid
        cols={{
          base: 1,
          sm: 2,
        }}
        spacing="xl"
        mt={48}
      >
        <ContactDetailsFormContainer />
        <CheckoutSummaryContainer />
      </SimpleGrid>
    </Container>
  </Container>
);

export default ContactDetailsPage;
