import { FC, useState } from "react";
import { Stack, Title } from "@mantine/core";
import styles from "./InvestmentOptions.module.css";
import { ProductsQuery } from "../../gql/graphql";
import InvestmentOption from "./InvestmentOption";
import CustomInvestmentForm from "./CustomInvestmentForm";
import PremadeInvestmentOption from "./PremadeInvestmentForm";
import { formatCurrency } from "../../utils";
import { productsToVariants } from "./utils";
import { useScrollIntoView } from "@mantine/hooks";
import { VariantWithRatio } from "../../types";
import { useTranslation } from "react-i18next";

const DEFAULT_SCROLL_OFFSET = 200;

type Props = {
  products: ProductsQuery["products"];
  onInvestClick: (variants: VariantWithRatio[]) => void;
  investmentGoal: number;
  investedAmount: number;
};

const InvestmentOptions: FC<Props> = ({
  products,
  onInvestClick,
  investmentGoal,
  investedAmount,
}) => {
  const [selectedOption, setSelectedOption] = useState("custom");

  const { t } = useTranslation();

  const isCustom = selectedOption === "custom";
  const isBronze = selectedOption === "bronze";
  const isSilver = selectedOption === "silver";
  const isGold = selectedOption === "gold";

  const scrollToCustom = useScrollIntoView<HTMLDivElement>({
    offset: DEFAULT_SCROLL_OFFSET,
  });

  const scrollToBronze = useScrollIntoView<HTMLDivElement>({
    offset: DEFAULT_SCROLL_OFFSET,
  });

  const scrollToSilver = useScrollIntoView<HTMLDivElement>({
    offset: DEFAULT_SCROLL_OFFSET,
  });

  const scrollToGold = useScrollIntoView<HTMLDivElement>({
    offset: DEFAULT_SCROLL_OFFSET,
  });

  const onInvestClickHandler = (variants: VariantWithRatio[]) => () => {
    onInvestClick(variants);
  };

  return (
    <Stack pt="md">
      {/* <Title ta="center" mt="lg" mb="xl" className={styles.title}>
        Vytvorte si investíciu na mieru
      </Title> */}
      <InvestmentOption
        ref={scrollToCustom.targetRef}
        selected={isCustom}
        onClick={() => {
          //scrollToCustom.scrollIntoView();
          return setSelectedOption("custom");
        }}
        packageClassName={""}
      >
        <CustomInvestmentForm
          selected={isCustom}
          products={products}
          investmentGoal={investmentGoal}
          investedAmount={investedAmount}
          onInvestClick={onInvestClickHandler}
        />
      </InvestmentOption>
      <Title order={2} ta="center" mt="xl" mb="xl" className={styles.title}>
        {t("campaign.investmentOptions.packagesTitle")}
      </Title>
      <InvestmentOption
        ref={scrollToBronze.targetRef}
        selected={isBronze}
        onClick={() => {
          //scrollToBronze.scrollIntoView();
          return setSelectedOption("bronze");
        }}
        packageClassName={styles.bronzePackage}
      >
        <PremadeInvestmentOption
          selected={isBronze}
          amount={5000}
          title={formatCurrency(5000)}
          label={t("campaign.investmentOptions.bronzePackage")}
          color="#cd7f32"
          products={productsToVariants(products)}
          onInvestClick={onInvestClickHandler}
        />
      </InvestmentOption>
      <InvestmentOption
        ref={scrollToSilver.targetRef}
        selected={isSilver}
        onClick={() => {
          //scrollToSilver.scrollIntoView();
          return setSelectedOption("silver");
        }}
        packageClassName={styles.silverPackage}
      >
        <PremadeInvestmentOption
          selected={isSilver}
          amount={10000}
          title={formatCurrency(10000)}
          label={t("campaign.investmentOptions.silverPackage")}
          color="#868E96"
          products={productsToVariants(products)}
          onInvestClick={onInvestClickHandler}
        />
      </InvestmentOption>
      <InvestmentOption
        ref={scrollToGold.targetRef}
        selected={isGold}
        onClick={() => {
          //scrollToGold.scrollIntoView();
          return setSelectedOption("gold");
        }}
        packageClassName={styles.goldPackage}
      >
        <PremadeInvestmentOption
          selected={isGold}
          amount={20000}
          title={formatCurrency(20000)}
          label={t("campaign.investmentOptions.goldPackage")}
          color="#FAB005"
          products={productsToVariants(products)}
          onInvestClick={onInvestClickHandler}
        />
      </InvestmentOption>
    </Stack>
  );
};

export default InvestmentOptions;
