import {
  Container,
  Title,
  Text,
  Paper,
  Loader,
  Stack,
  Button,
} from "@mantine/core";
import classes from "./SignInPage.module.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useOnMountUnsafe from "../hooks/useOnMountUnsafe";
import { showErrorNotification, showGraphQLErrorNotification } from "../utils";
import { useMutation } from "@apollo/client";
import { client } from "../app/apolloClient";
import {
  activeCustomerQueryDocument,
  verifyUserMutationDocument,
} from "../api/customer";
import { IconAlertCircle, IconCheck } from "@tabler/icons-react";
import { PageRoute } from "../components/PageRoute";
import { useTranslation } from "react-i18next";

const VerifyUserPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [verifyUser, { loading, data }] = useMutation(
    verifyUserMutationDocument,
    {
      onCompleted: (result) => {
        if (result.verifyCustomerAccount.__typename === "CurrentUser") {
          client.refetchQueries({ include: [activeCustomerQueryDocument] });
        } else if (
          result.verifyCustomerAccount.__typename === "MissingPasswordError"
        ) {
          navigate(
            PageRoute.VERIFY_USER_WITH_PASSWORD +
              "?token=" +
              searchParams.get("token")
          );
        } else {
          showGraphQLErrorNotification(result.verifyCustomerAccount);
        }
      },
      onError: (error) => {
        showErrorNotification(error.message);
      },
    }
  );

  useOnMountUnsafe(() => {
    setTimeout(onVerify);
  });

  const onVerify = () => {
    if (!searchParams.has("token")) {
      showErrorNotification(t("verifyUser.tokenMissing"));
      return;
    }
    verifyUser({ variables: { token: searchParams.get("token") as string } });
  };

  return (
    <Container size={500} my={40}>
      <Title ta="center" className={classes.title}>
        {t("verifyUser.verifyAccountTitle")}
      </Title>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Stack align="center">
          {loading && <Loader />}
          {!loading &&
            data?.verifyCustomerAccount.__typename === "CurrentUser" && (
              <>
                <IconCheck size={50} color="green" />

                <Text size="md" ta="center" mt={5} data-testid="successTitle">
                  {t("verifyUser.accountVerified")}
                </Text>
                <Button
                  component={Link}
                  to={PageRoute.HOME}
                  data-testid="homeBtn"
                >
                  {t("verifyUser.goToHomePage")}
                </Button>
              </>
            )}
          {!loading &&
            data &&
            data.verifyCustomerAccount.__typename !== "CurrentUser" && (
              <>
                <IconAlertCircle size={50} color="red" />
                <Text size="md" ta="center" mt={5}>
                  {t("verifyUser.verificationFailed")}{" "}
                  {data.verifyCustomerAccount.message}
                </Text>
              </>
            )}
        </Stack>
      </Paper>
    </Container>
  );
};

export default VerifyUserPage;
