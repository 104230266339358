import { graphql } from "../gql";

export const signUpMutationDocument = graphql(`
  mutation RegisterSeller($input: RegisterSellerInput!) {
    registerNewSeller(input: $input) {
      id
      code
      token
    }
  }
`);
