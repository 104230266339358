import { Paper, Text, Stack, Button, Group, Box } from "@mantine/core";
import { FC } from "react";
import { Link } from "react-router-dom";
import { PageRoute } from "../PageRoute";
import { useTranslation } from "react-i18next";
import { CampaignQuery } from "../../gql/graphql";
import VendureImage from "../VendureImage";

type Props = {
  id: string;
  isSignedIn: boolean;
  campaign: CampaignQuery["campaign"];
};

const InvestmentConfirmation: FC<Props> = ({ id, isSignedIn, campaign }) => {
  const { t } = useTranslation();
  return (
    <>
      {campaign?.image?.preview && (
        <Box px="xs">
          <VendureImage
            preview={campaign.image.preview}
            preset="medium"
            alt=""
            mt="xl"
            radius="md"
          />
        </Box>
      )}
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Stack align="center">
          <Text
            size="md"
            ta="center"
            mt={5}
            data-testid="confirmationText"
            data-code={id}
          >
            {isSignedIn
              ? t("investmentConfirmationTextSignedIn", { id })
              : t("investmentConfirmationTextGuest", { id })}
          </Text>

          <Group>
            {isSignedIn ? (
              <Button component={Link} to={PageRoute.MY_INVESTMENTS} mt={10}>
                {t("showInvestmentDetail")}
              </Button>
            ) : (
              <Button
                component="a"
                target="_blank"
                href="https://mail.google.com/"
                size="sm"
              >
                {t("openGmail")}
              </Button>
            )}
          </Group>
        </Stack>
      </Paper>
    </>
  );
};

export default InvestmentConfirmation;
