import { Container, Title, Text, Anchor } from "@mantine/core";
import classes from "./SignInPage.module.css";
import SignInFormContainer from "../components/SignInForm/SignInForm.container";
import { Link } from "react-router-dom";
import { PageRoute } from "../components/PageRoute";
import { useTranslation } from "react-i18next";

const SignInPage = () => {
  const { t } = useTranslation();

  return (
    <Container size={420} my={40}>
      <Title ta="center" className={classes.title}>
        {t("signIn.welcomeBack")}
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        {t("signIn.doNotHaveAccount")}{" "}
        <Anchor component={Link} to={PageRoute.SIGN_UP} size="sm">
          {t("signIn.createAccount")}
        </Anchor>
      </Text>
      <SignInFormContainer />
    </Container>
  );
};

export default SignInPage;
