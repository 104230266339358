import {
  Space,
  Container,
  Text,
  Stack,
  Title,
  SimpleGrid,
  Avatar,
  Paper,
} from "@mantine/core";
import UserNavbar from "../components/UserNavbar/UserNavbar";
import { useTranslation } from "react-i18next";

const ProfilePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Space h="72" />
      <Container size="xl">
        <UserNavbar>
          <Stack gap="xl" flex={1}>
            <Title order={2}>{t("profile")}</Title>
            <SimpleGrid cols={2} spacing="lg">
              <Paper withBorder p="lg" radius="md">
                <Stack align="center">
                  <Avatar size={128} radius={200} />
                  <Stack gap={0}>
                    <Text ta="center" size="xl">
                      John Doe
                    </Text>
                    <Text ta="center" c="dimmed">
                      johndoe@gmail.com
                    </Text>
                  </Stack>
                </Stack>
              </Paper>

              <Stack></Stack>
            </SimpleGrid>
          </Stack>
        </UserNavbar>
      </Container>
    </>
  );
};

export default ProfilePage;
