import { Container, Grid, Space } from "@mantine/core";
import Hero from "../components/Hero/Hero";
import CampaignListDesc from "../components/CampaignList/CampaignListDesc/CampaignListDesc";
import CampaignListContainer from "../components/CampaignList/CampaignList.container";

const HomePage = () => (
  <>
    <Hero />
    <Space h="72" />
    <Container size="xl" id="campaigns" data-testid="campaigns">
      <CampaignListDesc />
      <Grid mt="xl" gutter="xl">
        <CampaignListContainer />
      </Grid>
    </Container>
  </>
);

export default HomePage;
