import { FC, useState } from "react";
import {
  Button,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Title,
  Text,
  useMantineTheme,
  Group,
} from "@mantine/core";
import { IconBuildingBank, IconCreditCard } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

type Props = {
  isLoading: boolean;
  onSubmit: (paymentMethod: string) => void;
};

const PaymentMethodForm: FC<Props> = ({ isLoading, onSubmit }) => {
  const [paymentMethod, setPaymentMethod] = useState("creditCard");

  const { t } = useTranslation("translation", {
    keyPrefix: "checkout.paymentMethodForm",
  });

  const theme = useMantineTheme();

  const onPayClick = () => {
    onSubmit(paymentMethod);
  };

  const isCardPayment = paymentMethod === "creditCard";

  return (
    <Paper withBorder shadow="md" p="xl" radius="md" flex={1}>
      <Title order={4} mb="lg">
        {t("title")}
      </Title>
      <Stack>
        <RadioGroup value={paymentMethod} onChange={setPaymentMethod} size="xl">
          <Stack gap="sm">
            <Paper
              shadow="none"
              withBorder
              radius="md"
              style={{
                borderColor: isCardPayment
                  ? theme.colors.myColor[4]
                  : undefined,
                cursor: "pointer",
              }}
            >
              <Radio
                value="creditCard"
                labelPosition="left"
                label={
                  <Group>
                    <IconCreditCard
                      size="2rem"
                      stroke={1.2}
                      color={
                        isCardPayment ? theme.colors.myColor[8] : undefined
                      }
                    />
                    <Text
                      size="sm"
                      fw={500}
                      c={isCardPayment ? "myColor.8" : "gray.8"}
                    >
                      {t("payByCard")}
                    </Text>
                  </Group>
                }
                data-testid="cardPayment"
              />
            </Paper>
            <Paper
              shadow="none"
              withBorder
              radius="md"
              style={{
                borderColor: !isCardPayment
                  ? theme.colors.myColor[4]
                  : undefined,
              }}
            >
              <Radio
                value="bankTransfer"
                labelPosition="left"
                label={
                  <Group>
                    <IconBuildingBank
                      size="1.7rem"
                      stroke={1.2}
                      color={
                        !isCardPayment ? theme.colors.myColor[8] : undefined
                      }
                    />
                    <Text
                      size="sm"
                      fw={500}
                      c={!isCardPayment ? "myColor.8" : "gray.8"}
                    >
                      {t("payByWireTransfer")}
                    </Text>
                  </Group>
                }
                data-testid="wireTransferPayment"
              />
            </Paper>
          </Stack>
        </RadioGroup>

        <Button
          onClick={onPayClick}
          type="submit"
          fullWidth
          mt="xs"
          loading={isLoading}
          data-testid="payButton"
        >
          {t("submitButton")}
        </Button>
      </Stack>
    </Paper>
  );
};

export default PaymentMethodForm;
