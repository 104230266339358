import { Text, Group, Badge, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import classes from "./CampaignListDesc.module.css";

const CampaignListDesc = () => {
  const { t } = useTranslation();
  return (
    <>
      <Group justify="center">
        <Badge variant="filled" size="lg">
          {t("sustainableInvestments")}
        </Badge>
      </Group>

      <Title order={2} className={classes.title} ta="center" mt="sm">
        {t("supportLocalFarms")}
      </Title>

      <Text c="dimmed" className={classes.description} ta="center" mt="md">
        {t("connectWithFarmers")}
      </Text>
    </>
  );
};

export default CampaignListDesc;
