/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation VerifyAdmin($token: String!) {\n    verifyAdminAccount(token: $token)\n  }\n": types.VerifyAdminDocument,
    "\n  query campaigns {\n    campaigns {\n      id\n      goal\n      investedAmount\n      harvestDate\n      status\n      title\n      description\n      location\n      launchDate\n      durationDays\n      storyText\n      isOrganic\n      numOfEmployees\n      farmArea\n      totalInvestors\n      image {\n        id\n        preview\n      }\n      seller {\n        id\n        name\n        customFields {\n          channelToken\n        }\n      }\n    }\n  }\n": types.CampaignsDocument,
    "\n  query campaign($id: ID!) {\n    campaign(id: $id) {\n      id\n      goal\n      investedAmount\n      harvestDate\n      status\n      title\n      description\n      location\n      launchDate\n      durationDays\n      storyText\n      isOrganic\n      numOfEmployees\n      farmArea\n      totalInvestors\n      image {\n        id\n        preview\n      }\n      seller {\n        id\n        name\n      }\n    }\n  }\n": types.CampaignDocument,
    "\n  mutation clearCart {\n    removeAllOrderLines {\n      __typename\n\n      ... on Order {\n        id\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n": types.ClearCartDocument,
    "\n  mutation AddItemToOrder($productVariantId: ID!, $quantity: Int!) {\n    addItemToOrder(productVariantId: $productVariantId, quantity: $quantity) {\n      __typename\n      ... on Order {\n        id\n        code\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n      ... on InsufficientStockError {\n        quantityAvailable\n      }\n    }\n  }\n": types.AddItemToOrderDocument,
    "\n  query activeCustomer {\n    activeCustomer {\n      id\n      firstName\n      lastName\n      emailAddress\n      orders {\n        items {\n          id\n          code\n          createdAt\n          state\n          customFields {\n            campaignId\n          }\n          payments {\n            amount\n            method\n            metadata\n            state\n          }\n          fulfillments {\n            id\n            createdAt\n            state\n            customFields {\n              downloadUrls\n            }\n          }\n          lines {\n            id\n            quantity\n            linePriceWithTax\n            productVariant {\n              product {\n                name\n                description\n                featuredAsset {\n                  preview\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.ActiveCustomerDocument,
    "\n  mutation SetCustomerForOrder($input: CreateCustomerInput!) {\n    setCustomerForOrder(input: $input) {\n      ...ActiveOrder\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n": types.SetCustomerForOrderDocument,
    "\n  mutation LogOut {\n    logout {\n      success\n    }\n  }\n": types.LogOutDocument,
    "\n  mutation Register($input: RegisterCustomerInput!) {\n    registerCustomerAccount(input: $input) {\n      __typename\n      ... on Success {\n        success\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n": types.RegisterDocument,
    "\n  mutation LogIn(\n    $emailAddress: String!\n    $password: String!\n    $rememberMe: Boolean!\n  ) {\n    login(\n      username: $emailAddress\n      password: $password\n      rememberMe: $rememberMe\n    ) {\n      __typename\n      ... on CurrentUser {\n        id\n        identifier\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n": types.LogInDocument,
    "\n  mutation Verify($token: String!, $password: String) {\n    verifyCustomerAccount(token: $token, password: $password) {\n      __typename\n      ... on CurrentUser {\n        id\n        identifier\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n": types.VerifyDocument,
    "\n  mutation SetOrderShippingAddress($input: CreateAddressInput!) {\n    setOrderShippingAddress(input: $input) {\n      __typename\n      ... on Order {\n        id\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n": types.SetOrderShippingAddressDocument,
    "\n  mutation SetShippingMethod($id: [ID!]!) {\n    setOrderShippingMethod(shippingMethodId: $id) {\n      ...ActiveOrder\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n": types.SetShippingMethodDocument,
    "\n  query EligibleShippingMethods {\n    eligibleShippingMethods {\n      id\n      code\n      name\n    }\n  }\n": types.EligibleShippingMethodsDocument,
    "\n  mutation SetOrderCustomFields($input: UpdateOrderInput!) {\n    setOrderCustomFields(input: $input) {\n      ...ActiveOrder\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n": types.SetOrderCustomFieldsDocument,
    "\n  query GetOrderByCode($code: String!) {\n    orderByCode(code: $code) {\n      id\n      code\n      state\n      lines {\n        id\n        unitPriceWithTax\n        quantity\n        linePriceWithTax\n        productVariant {\n          id\n          name\n          sku\n          product {\n            name\n            customFields {\n              ratio\n            }\n          }\n        }\n        featuredAsset {\n          id\n          preview\n        }\n      }\n      totalWithTax\n      customFields {\n        campaignId\n      }\n    }\n  }\n": types.GetOrderByCodeDocument,
    "\n  mutation AddPaymentToOrder($input: PaymentInput!) {\n    addPaymentToOrder(input: $input) {\n      ... on Order {\n        code\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n": types.AddPaymentToOrderDocument,
    "\n  mutation TransitionToState($state: String!) {\n    transitionOrderToState(state: $state) {\n      ...ActiveOrder\n      ... on OrderStateTransitionError {\n        errorCode\n        message\n        transitionError\n        fromState\n        toState\n      }\n    }\n  }\n": types.TransitionToStateDocument,
    "\n  query GetActiveOrder {\n    activeOrder {\n      code\n      state\n      lines {\n        id\n        unitPriceWithTax\n        quantity\n        linePriceWithTax\n        productVariant {\n          id\n          name\n          sku\n          product {\n            name\n            customFields {\n              ratio\n            }\n          }\n        }\n        featuredAsset {\n          id\n          preview\n        }\n      }\n      totalWithTax\n      customFields {\n        campaignId\n      }\n    }\n  }\n": types.GetActiveOrderDocument,
    "\n  mutation CreatePaymentIntent {\n    createStripePaymentIntent\n  }\n": types.CreatePaymentIntentDocument,
    "\n  query products($options: ProductListOptions) {\n    products(options: $options) {\n      items {\n        id\n        name\n        description\n        customFields {\n          ratio\n        }\n        cropObservations {\n          itemCode\n          item\n          year\n          value\n          isPrediction\n        }\n        variants {\n          id\n          name\n          sku\n          price\n          stockLevel\n          featuredAsset {\n            preview\n          }\n        }\n      }\n    }\n  }\n": types.ProductsDocument,
    "\n  mutation RegisterSeller($input: RegisterSellerInput!) {\n    registerNewSeller(input: $input) {\n      id\n      code\n      token\n    }\n  }\n": types.RegisterSellerDocument,
    "\n  fragment ActiveOrder on Order {\n    __typename\n    id\n    code\n    couponCodes\n    state\n    currencyCode\n    totalQuantity\n    subTotalWithTax\n    shippingWithTax\n    totalWithTax\n    discounts {\n      description\n      amountWithTax\n    }\n    lines {\n      id\n      unitPriceWithTax\n      quantity\n      linePriceWithTax\n      productVariant {\n        id\n        name\n        sku\n      }\n      featuredAsset {\n        id\n        preview\n      }\n    }\n    shippingLines {\n      shippingMethod {\n        description\n      }\n      priceWithTax\n    }\n  }\n": types.ActiveOrderFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation VerifyAdmin($token: String!) {\n    verifyAdminAccount(token: $token)\n  }\n"): (typeof documents)["\n  mutation VerifyAdmin($token: String!) {\n    verifyAdminAccount(token: $token)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query campaigns {\n    campaigns {\n      id\n      goal\n      investedAmount\n      harvestDate\n      status\n      title\n      description\n      location\n      launchDate\n      durationDays\n      storyText\n      isOrganic\n      numOfEmployees\n      farmArea\n      totalInvestors\n      image {\n        id\n        preview\n      }\n      seller {\n        id\n        name\n        customFields {\n          channelToken\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query campaigns {\n    campaigns {\n      id\n      goal\n      investedAmount\n      harvestDate\n      status\n      title\n      description\n      location\n      launchDate\n      durationDays\n      storyText\n      isOrganic\n      numOfEmployees\n      farmArea\n      totalInvestors\n      image {\n        id\n        preview\n      }\n      seller {\n        id\n        name\n        customFields {\n          channelToken\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query campaign($id: ID!) {\n    campaign(id: $id) {\n      id\n      goal\n      investedAmount\n      harvestDate\n      status\n      title\n      description\n      location\n      launchDate\n      durationDays\n      storyText\n      isOrganic\n      numOfEmployees\n      farmArea\n      totalInvestors\n      image {\n        id\n        preview\n      }\n      seller {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query campaign($id: ID!) {\n    campaign(id: $id) {\n      id\n      goal\n      investedAmount\n      harvestDate\n      status\n      title\n      description\n      location\n      launchDate\n      durationDays\n      storyText\n      isOrganic\n      numOfEmployees\n      farmArea\n      totalInvestors\n      image {\n        id\n        preview\n      }\n      seller {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation clearCart {\n    removeAllOrderLines {\n      __typename\n\n      ... on Order {\n        id\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation clearCart {\n    removeAllOrderLines {\n      __typename\n\n      ... on Order {\n        id\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddItemToOrder($productVariantId: ID!, $quantity: Int!) {\n    addItemToOrder(productVariantId: $productVariantId, quantity: $quantity) {\n      __typename\n      ... on Order {\n        id\n        code\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n      ... on InsufficientStockError {\n        quantityAvailable\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddItemToOrder($productVariantId: ID!, $quantity: Int!) {\n    addItemToOrder(productVariantId: $productVariantId, quantity: $quantity) {\n      __typename\n      ... on Order {\n        id\n        code\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n      ... on InsufficientStockError {\n        quantityAvailable\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query activeCustomer {\n    activeCustomer {\n      id\n      firstName\n      lastName\n      emailAddress\n      orders {\n        items {\n          id\n          code\n          createdAt\n          state\n          customFields {\n            campaignId\n          }\n          payments {\n            amount\n            method\n            metadata\n            state\n          }\n          fulfillments {\n            id\n            createdAt\n            state\n            customFields {\n              downloadUrls\n            }\n          }\n          lines {\n            id\n            quantity\n            linePriceWithTax\n            productVariant {\n              product {\n                name\n                description\n                featuredAsset {\n                  preview\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query activeCustomer {\n    activeCustomer {\n      id\n      firstName\n      lastName\n      emailAddress\n      orders {\n        items {\n          id\n          code\n          createdAt\n          state\n          customFields {\n            campaignId\n          }\n          payments {\n            amount\n            method\n            metadata\n            state\n          }\n          fulfillments {\n            id\n            createdAt\n            state\n            customFields {\n              downloadUrls\n            }\n          }\n          lines {\n            id\n            quantity\n            linePriceWithTax\n            productVariant {\n              product {\n                name\n                description\n                featuredAsset {\n                  preview\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetCustomerForOrder($input: CreateCustomerInput!) {\n    setCustomerForOrder(input: $input) {\n      ...ActiveOrder\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetCustomerForOrder($input: CreateCustomerInput!) {\n    setCustomerForOrder(input: $input) {\n      ...ActiveOrder\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation LogOut {\n    logout {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation LogOut {\n    logout {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Register($input: RegisterCustomerInput!) {\n    registerCustomerAccount(input: $input) {\n      __typename\n      ... on Success {\n        success\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Register($input: RegisterCustomerInput!) {\n    registerCustomerAccount(input: $input) {\n      __typename\n      ... on Success {\n        success\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation LogIn(\n    $emailAddress: String!\n    $password: String!\n    $rememberMe: Boolean!\n  ) {\n    login(\n      username: $emailAddress\n      password: $password\n      rememberMe: $rememberMe\n    ) {\n      __typename\n      ... on CurrentUser {\n        id\n        identifier\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation LogIn(\n    $emailAddress: String!\n    $password: String!\n    $rememberMe: Boolean!\n  ) {\n    login(\n      username: $emailAddress\n      password: $password\n      rememberMe: $rememberMe\n    ) {\n      __typename\n      ... on CurrentUser {\n        id\n        identifier\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Verify($token: String!, $password: String) {\n    verifyCustomerAccount(token: $token, password: $password) {\n      __typename\n      ... on CurrentUser {\n        id\n        identifier\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Verify($token: String!, $password: String) {\n    verifyCustomerAccount(token: $token, password: $password) {\n      __typename\n      ... on CurrentUser {\n        id\n        identifier\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetOrderShippingAddress($input: CreateAddressInput!) {\n    setOrderShippingAddress(input: $input) {\n      __typename\n      ... on Order {\n        id\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetOrderShippingAddress($input: CreateAddressInput!) {\n    setOrderShippingAddress(input: $input) {\n      __typename\n      ... on Order {\n        id\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetShippingMethod($id: [ID!]!) {\n    setOrderShippingMethod(shippingMethodId: $id) {\n      ...ActiveOrder\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetShippingMethod($id: [ID!]!) {\n    setOrderShippingMethod(shippingMethodId: $id) {\n      ...ActiveOrder\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EligibleShippingMethods {\n    eligibleShippingMethods {\n      id\n      code\n      name\n    }\n  }\n"): (typeof documents)["\n  query EligibleShippingMethods {\n    eligibleShippingMethods {\n      id\n      code\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetOrderCustomFields($input: UpdateOrderInput!) {\n    setOrderCustomFields(input: $input) {\n      ...ActiveOrder\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetOrderCustomFields($input: UpdateOrderInput!) {\n    setOrderCustomFields(input: $input) {\n      ...ActiveOrder\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrderByCode($code: String!) {\n    orderByCode(code: $code) {\n      id\n      code\n      state\n      lines {\n        id\n        unitPriceWithTax\n        quantity\n        linePriceWithTax\n        productVariant {\n          id\n          name\n          sku\n          product {\n            name\n            customFields {\n              ratio\n            }\n          }\n        }\n        featuredAsset {\n          id\n          preview\n        }\n      }\n      totalWithTax\n      customFields {\n        campaignId\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetOrderByCode($code: String!) {\n    orderByCode(code: $code) {\n      id\n      code\n      state\n      lines {\n        id\n        unitPriceWithTax\n        quantity\n        linePriceWithTax\n        productVariant {\n          id\n          name\n          sku\n          product {\n            name\n            customFields {\n              ratio\n            }\n          }\n        }\n        featuredAsset {\n          id\n          preview\n        }\n      }\n      totalWithTax\n      customFields {\n        campaignId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddPaymentToOrder($input: PaymentInput!) {\n    addPaymentToOrder(input: $input) {\n      ... on Order {\n        code\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddPaymentToOrder($input: PaymentInput!) {\n    addPaymentToOrder(input: $input) {\n      ... on Order {\n        code\n      }\n      ... on ErrorResult {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation TransitionToState($state: String!) {\n    transitionOrderToState(state: $state) {\n      ...ActiveOrder\n      ... on OrderStateTransitionError {\n        errorCode\n        message\n        transitionError\n        fromState\n        toState\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation TransitionToState($state: String!) {\n    transitionOrderToState(state: $state) {\n      ...ActiveOrder\n      ... on OrderStateTransitionError {\n        errorCode\n        message\n        transitionError\n        fromState\n        toState\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetActiveOrder {\n    activeOrder {\n      code\n      state\n      lines {\n        id\n        unitPriceWithTax\n        quantity\n        linePriceWithTax\n        productVariant {\n          id\n          name\n          sku\n          product {\n            name\n            customFields {\n              ratio\n            }\n          }\n        }\n        featuredAsset {\n          id\n          preview\n        }\n      }\n      totalWithTax\n      customFields {\n        campaignId\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetActiveOrder {\n    activeOrder {\n      code\n      state\n      lines {\n        id\n        unitPriceWithTax\n        quantity\n        linePriceWithTax\n        productVariant {\n          id\n          name\n          sku\n          product {\n            name\n            customFields {\n              ratio\n            }\n          }\n        }\n        featuredAsset {\n          id\n          preview\n        }\n      }\n      totalWithTax\n      customFields {\n        campaignId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePaymentIntent {\n    createStripePaymentIntent\n  }\n"): (typeof documents)["\n  mutation CreatePaymentIntent {\n    createStripePaymentIntent\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query products($options: ProductListOptions) {\n    products(options: $options) {\n      items {\n        id\n        name\n        description\n        customFields {\n          ratio\n        }\n        cropObservations {\n          itemCode\n          item\n          year\n          value\n          isPrediction\n        }\n        variants {\n          id\n          name\n          sku\n          price\n          stockLevel\n          featuredAsset {\n            preview\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query products($options: ProductListOptions) {\n    products(options: $options) {\n      items {\n        id\n        name\n        description\n        customFields {\n          ratio\n        }\n        cropObservations {\n          itemCode\n          item\n          year\n          value\n          isPrediction\n        }\n        variants {\n          id\n          name\n          sku\n          price\n          stockLevel\n          featuredAsset {\n            preview\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RegisterSeller($input: RegisterSellerInput!) {\n    registerNewSeller(input: $input) {\n      id\n      code\n      token\n    }\n  }\n"): (typeof documents)["\n  mutation RegisterSeller($input: RegisterSellerInput!) {\n    registerNewSeller(input: $input) {\n      id\n      code\n      token\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ActiveOrder on Order {\n    __typename\n    id\n    code\n    couponCodes\n    state\n    currencyCode\n    totalQuantity\n    subTotalWithTax\n    shippingWithTax\n    totalWithTax\n    discounts {\n      description\n      amountWithTax\n    }\n    lines {\n      id\n      unitPriceWithTax\n      quantity\n      linePriceWithTax\n      productVariant {\n        id\n        name\n        sku\n      }\n      featuredAsset {\n        id\n        preview\n      }\n    }\n    shippingLines {\n      shippingMethod {\n        description\n      }\n      priceWithTax\n    }\n  }\n"): (typeof documents)["\n  fragment ActiveOrder on Order {\n    __typename\n    id\n    code\n    couponCodes\n    state\n    currencyCode\n    totalQuantity\n    subTotalWithTax\n    shippingWithTax\n    totalWithTax\n    discounts {\n      description\n      amountWithTax\n    }\n    lines {\n      id\n      unitPriceWithTax\n      quantity\n      linePriceWithTax\n      productVariant {\n        id\n        name\n        sku\n      }\n      featuredAsset {\n        id\n        preview\n      }\n    }\n    shippingLines {\n      shippingMethod {\n        description\n      }\n      priceWithTax\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;