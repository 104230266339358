import { useNavigate } from "react-router-dom";
import { showErrorNotification } from "../utils";
import { useMutation } from "@apollo/client";
import { client } from "../app/apolloClient";
import {
  activeCustomerQueryDocument,
  signOutMutationDocument,
} from "../api/customer";

const useSignOut = () => {
  const navigate = useNavigate();

  const [signOut, { loading }] = useMutation(signOutMutationDocument, {
    onCompleted: (result) => {
      if (result.logout.success) {
        client.refetchQueries({ include: [activeCustomerQueryDocument] });
        navigate("/");
      } else {
        showErrorNotification("Could not log out");
      }
    },
    onError: (error) => {
      showErrorNotification(error.message);
    },
  });

  return { loading, signOut };
};

export default useSignOut;
