import { Container, Title, Text, Anchor } from "@mantine/core";
import classes from "./SignInPage.module.css";
import { Link } from "react-router-dom";
import SellerSignInFormContainer from "../components/SellerSignUpForm/SellerSignUpForm.container";
import { useTranslation } from "react-i18next";

const adminUrl = import.meta.env.VITE_ADMIN_URL;

const SellerSignUpPage = () => {
  const { t } = useTranslation();
  return (
    <Container size={420} my={40}>
      <Title ta="center" className={classes.title}>
        {t("sellerSignUp.createSellerAccount")}
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        {t("sellerSignUp.alreadyHaveSellerAccount")}{" "}
        <Anchor component={Link} to={adminUrl} size="sm">
          {t("sellerSignUp.signIn")}
        </Anchor>
      </Text>
      <SellerSignInFormContainer />
    </Container>
  );
};

export default SellerSignUpPage;
