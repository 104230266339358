import { Image, ImageProps } from '@mantine/core';
import { FC } from 'react';

interface Props extends ImageProps {
  preview: string;
  preset: 'tiny' | 'thumb' | 'small' | 'medium' | 'large';
  alt: string;
}

const VendureImage: FC<Props> = ({ preview, preset, alt, ...props }) => {
  return (
    <picture>
      <source
        type="image/avif"
        srcSet={preview + `?preset=${preset}&format=avif`}
      />
      <source
        type="image/webp"
        srcSet={preview + `?preset=${preset}&format=webp`}
      />
      <Image
        src={preview + `?preset=${preset}&format=jpg`}
        alt={alt}
        {...props}
      />
    </picture>
  );
};

export default VendureImage;
