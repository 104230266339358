import SignInForm from "./SignInForm";
import { LogInMutationVariables } from "../../gql/graphql";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  showErrorNotification,
  showGraphQLErrorNotification,
} from "../../utils";
import { useMutation } from "@apollo/client";
import { client } from "../../app/apolloClient";
import {
  activeCustomerQueryDocument,
  signInMutationDocument,
} from "../../api/customer";

const SignInFormContainer = () => {
  const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);

  const navigate = useNavigate();

  const [signIn, { loading }] = useMutation(signInMutationDocument, {
    onCompleted: (result) => {
      if (result.login.__typename === "CurrentUser") {
        client.refetchQueries({ include: [activeCustomerQueryDocument] });
        navigate("/");
      } else if (result.login.__typename === "InvalidCredentialsError") {
        setIsPasswordIncorrect(true);
      } else {
        showGraphQLErrorNotification(result.login);
      }
    },
    onError: (error) => {
      showErrorNotification(error.message);
    },
  });

  const onSubmit = (values: LogInMutationVariables) => {
    setIsPasswordIncorrect(false);
    signIn({ variables: values });
  };

  return (
    <SignInForm
      onSubmit={onSubmit}
      isLoading={loading}
      isPasswordIncorrect={isPasswordIncorrect}
    />
  );
};

export default SignInFormContainer;
