import { AppShell as MantineAppShell } from "@mantine/core";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop";
import { useDisclosure } from "@mantine/hooks";
import Header from "./Header";
import Navbar from "./Navbar";
import Routes from "../Routes";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { activeCustomerQueryDocument } from "../../api/customer";

const AppShell = () => {
  const [opened, { toggle, close }] = useDisclosure();

  const { pathname } = useLocation();

  const activeCustomerQuery = useQuery(activeCustomerQueryDocument, {
    onCompleted: (data) => {
      if (!data?.activeCustomer) {
        localStorage.removeItem("vendure-token");
      }
    },
  });

  const activeCustomer = activeCustomerQuery.data?.activeCustomer;

  useEffect(() => {
    close();
  }, [pathname]);

  return (
    <MantineAppShell
      header={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: "md",
        collapsed: { desktop: true, mobile: !opened },
      }}
      padding="0"
    >
      <MantineAppShell.Header className="header">
        <Header
          menuOpened={opened}
          toggleMenu={toggle}
          activeCustomer={activeCustomer}
          isLoading={activeCustomerQuery.loading}
        />
      </MantineAppShell.Header>

      <MantineAppShell.Navbar py="md" px={4}>
        <Navbar activeCustomer={activeCustomer} />
      </MantineAppShell.Navbar>

      <MantineAppShell.Main>
        <ScrollToTop />
        <Routes />
      </MantineAppShell.Main>
      <Footer />
    </MantineAppShell>
  );
};

export default AppShell;
