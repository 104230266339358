import { Avatar, Badge, Stack, Table, Text, Title } from "@mantine/core";
import { CampaignQuery, ProductsQuery } from "../../gql/graphql";
import { FC } from "react";
import { IconApple } from "@tabler/icons-react";
import { formatCurrency } from "../../utils";
import { useTranslation } from "react-i18next";

type Props = {
  campaign: CampaignQuery["campaign"];
  products: ProductsQuery["products"];
};

const TableHarvestPlan: FC<Props> = ({ campaign, products }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "campaign.harvestPlan",
  });

  const rows = products.items.map((product) => {
    const val23 = product.cropObservations.find(
      (item) => item.year === 2023
    )?.value;
    const val24 = product.cropObservations.find(
      (item) => item.year === 2024
    )?.value;

    const changePercent = val23 && val24 ? ((val24 - val23) / val23) * 100 : 0;

    return (
      <Table.Tr key={product.name}>
        <Table.Td>
          <Avatar size={40} radius={40}>
            <IconApple size="1.5rem" />
          </Avatar>
        </Table.Td>
        <Table.Td>
          <Stack gap={0}>
            <Text fz="md" fw={500}>
              {product.name}
            </Text>
            <Text c="dimmed" fz="sm">
              {product.description}
            </Text>
          </Stack>
        </Table.Td>

        <Table.Td>
          <Text>
            <Badge variant="light" size="lg">
              {Math.round(product.customFields?.ratio ?? 0) * 100} {"%"}
            </Badge>
          </Text>
        </Table.Td>
        <Table.Td>
          <Text>
            {formatCurrency(
              (product.customFields?.ratio ?? 0) * (campaign?.goal ?? 0)
            )}
          </Text>
        </Table.Td>

        <Table.Td>
          <Text
            c={changePercent ? "myColor" : undefined}
            size={changePercent ? "md" : "sm"}
            fw={600}
          >
            {changePercent ? `${changePercent.toFixed(0)}%` : t("unavailable")}
          </Text>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <Stack>
      <Title order={2} mb="md">
        {t("title")}
      </Title>
      <Table verticalSpacing="xs">
        <Table.Thead>
          <Table.Tr>
            <Table.Th></Table.Th>
            <Table.Th>{t("cropName")}</Table.Th>
            <Table.Th>{t("ratio")}</Table.Th>
            <Table.Th>{t("budget")}</Table.Th>
            <Table.Th>{t("yieldEstimate")}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Stack>
  );
};

export default TableHarvestPlan;
