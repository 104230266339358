import {
  Anchor,
  Button,
  Group,
  Paper,
  PasswordInput,
  TextInput,
} from "@mantine/core";
import { FC } from "react";
import { useForm } from "@mantine/form";
import { RegisterCustomerInput } from "../../gql/graphql";
import { useTranslation } from "react-i18next";

const extractRegistrationFormValues = (
  formValues: SignUpFormValues
): RegisterCustomerInput => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { repeatPassword, ...input } = formValues;
  return input;
};
type SignUpFormValues = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  password: string;
  repeatPassword: string;
};

type Props = {
  isLoading: boolean;
  onSubmit: (values: RegisterCustomerInput) => void;
};

const SignUpForm: FC<Props> = ({ isLoading, onSubmit }) => {
  const { t } = useTranslation();
  const form = useForm<SignUpFormValues>({
    initialValues: {
      emailAddress: "",
      firstName: "",
      lastName: "",
      password: "",
      repeatPassword: "",
    },

    validate: {
      emailAddress: (value) =>
        /^\S+@\S+$/.test(value) ? null : t("signUp.invalidEmail"),
      password: (value) =>
        value.length >= 4 ? null : t("signUp.shortPassword"),
      repeatPassword: (value, values) =>
        value === values.password ? null : t("signUp.passwordsDoNotMatch"),
    },
  });
  const handleSubmit = (values: SignUpFormValues) => {
    onSubmit(extractRegistrationFormValues(values));
  };

  return (
    <Paper withBorder shadow="md" p={30} mt={30} radius="md">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          label={t("signUp.email")}
          placeholder={t("signUp.yourEmail")}
          required
          id="email"
          data-testid="email"
          {...form.getInputProps("emailAddress")}
        />
        <TextInput
          label={t("signUp.firstName")}
          placeholder={t("signUp.firstName")}
          required
          mt="md"
          data-testid="first-name"
          {...form.getInputProps("firstName")}
        />
        <TextInput
          label={t("signUp.lastName")}
          placeholder={t("signUp.lastName")}
          required
          mt="md"
          data-testid="last-name"
          {...form.getInputProps("lastName")}
        />
        <PasswordInput
          label={t("signUp.password")}
          placeholder={t("signUp.enterPassword")}
          required
          mt="md"
          data-testid="password"
          {...form.getInputProps("password")}
        />
        <PasswordInput
          label={t("signUp.repeatPassword")}
          placeholder={t("signUp.repeatPasswordPlaceholder")}
          required
          mt="md"
          data-testid="repeat-password"
          {...form.getInputProps("repeatPassword")}
        />
        <Group justify="space-between" mt="lg">
          <Anchor component="button" size="sm">
            {t("signUp.forgotPassword")}
          </Anchor>
        </Group>
        <Button
          type="submit"
          fullWidth
          mt="xl"
          loading={isLoading}
          data-testid="sign-up-btn"
        >
          {t("signUp.createAccountButton")}
        </Button>
      </form>
    </Paper>
  );
};

export default SignUpForm;
