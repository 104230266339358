import { Button, Group, Paper, TextInput } from "@mantine/core";
import { FC } from "react";
import { useForm } from "@mantine/form";

export type ContactDetailsFormValuesRegistered = {
  street: string;
  streetNumber: string;
  city: string;
  zip: string;
};

type Props = {
  isLoading: boolean;
  onSubmit: (values: ContactDetailsFormValuesRegistered) => void;
};

const ContactDetailsFormRegistered: FC<Props> = ({ isLoading, onSubmit }) => {
  const form = useForm<ContactDetailsFormValuesRegistered>({
    initialValues: {
      street: "",
      streetNumber: "",
      city: "",
      zip: "",
    },
  });

  return (
    <Paper withBorder shadow="md" p={30} radius="md">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Group>
          <TextInput
            label="Ulica"
            placeholder="Ulica"
            required
            style={{ flex: 1 }}
            {...form.getInputProps("street")}
          />
          <TextInput
            label="Číslo ulice"
            placeholder="Číslo ulice"
            required
            style={{ flex: 0.7 }}
            {...form.getInputProps("streetNumber")}
          />
        </Group>

        <TextInput
          label="Obec"
          placeholder="Obec"
          required
          mt="md"
          {...form.getInputProps("city")}
        />
        <TextInput
          label="PSČ"
          placeholder="PSČ"
          required
          mt="md"
          {...form.getInputProps("zip")}
        />

        <Button type="submit" fullWidth mt="xl" loading={isLoading}>
          Pokračovať k platbe
        </Button>
      </form>
    </Paper>
  );
};

export default ContactDetailsFormRegistered;
