import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { signUpMutationDocument } from "../../api/customer";
import { RegisterCustomerInput } from "../../gql/graphql";
import {
  showErrorNotification,
  showGraphQLErrorNotification,
} from "../../utils";
import SignUpForm from "./SignUpForm";

const SignInFormContainer = () => {
  const navigate = useNavigate();

  const [signUp, { loading }] = useMutation(signUpMutationDocument, {
    onError: (error) => {
      showErrorNotification(error.message);
    },
  });

  const onSubmit = async (values: RegisterCustomerInput) => {
    const res = await signUp({ variables: { input: values } });
    if (res.data?.registerCustomerAccount?.__typename === "Success") {
      navigate("/sign-up-confirmation", {
        state: { email: values.emailAddress },
      });
    } else {
      if (res.data)
        showGraphQLErrorNotification(res.data?.registerCustomerAccount);
    }
  };

  return <SignUpForm onSubmit={onSubmit} isLoading={loading} />;
};

export default SignInFormContainer;
