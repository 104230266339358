import {
  Progress,
  Text,
  Group,
  Badge,
  Paper,
  Button,
  Tooltip,
} from "@mantine/core";
import { FC } from "react";
import { formatCurrency } from "../../utils";
import { Link } from "react-router-dom";
import { PageRoute } from "../PageRoute";
import { CampaignQuery, CampaignStatus } from "../../gql/graphql";
import { useTranslation } from "react-i18next";
import {
  calculateDaysRemaining,
  calculateDateDifference,
} from "../CampaignCard/utils";

type Props = {
  campaign: CampaignQuery["campaign"];
};

const StatsCard: FC<Props> = ({ campaign }) => {
  const { t } = useTranslation();

  const investedAmount = campaign?.investedAmount ?? 0;
  const investmentGoal = campaign?.goal ?? 0;

  const progress = (investedAmount / investmentGoal) * 100;

  const investedAmountFormatted = formatCurrency(investedAmount);
  const investmentGoalFormatted = formatCurrency(investmentGoal);

  const isCampaignActive = campaign?.status === CampaignStatus.Active;

  const launchDate = new Date(campaign?.launchDate);
  const duration = campaign?.durationDays ?? 0;
  const endDate = new Date(launchDate);
  endDate.setDate(launchDate.getDate() + duration);

  const daysRemaining = calculateDaysRemaining(campaign?.launchDate, duration);
  const daysUntilStart = calculateDateDifference(launchDate, true);

  const getCampaignStatus = () => {
    if (campaign?.status === CampaignStatus.Active) {
      return (
        <Badge variant="light" size="md">
          {t("campaign.card.daysRemaining", { days: daysRemaining })}
        </Badge>
      );
    }
    if (campaign?.status === CampaignStatus.Scheduled) {
      return (
        <Badge variant="light" size="md" color="blue">
          {t("campaign.card.startsIn", { days: daysUntilStart })}
        </Badge>
      );
    }
    if (campaign?.status === CampaignStatus.Ended) {
      return (
        <Badge variant="light" size="md" color="gray">
          {t("campaign.card.ended")}
        </Badge>
      );
    }

    return null;
  };

  const campaignStatusBadge = getCampaignStatus();

  return (
    <Group align="flex-start">
      <Paper radius="md" withBorder p="xl" flex={1}>
        <Text size="lg" ta="center" fw={700}>
          {t("campaign.detail.financing")}
        </Text>

        <Group justify="space-between" mt="xs">
          <Text fz="sm" c="dimmed">
            {t("campaign.detail.progress")}
          </Text>
          <Text fz="sm" c="dimmed">
            {Math.round(progress)}%
          </Text>
        </Group>

        <Progress value={progress} mt={5} />

        <Group justify="space-between" mt="md">
          <Text fz="sm" fw={500} data-testid="fundingStatus">
            {investedAmountFormatted} / {investmentGoalFormatted}{" "}
          </Text>
          {campaignStatusBadge}
        </Group>

        <Tooltip
          label={t("campaign.detail.investDisabled")}
          offset={7}
          disabled={isCampaignActive}
        >
          <Button
            component={Link}
            to={isCampaignActive ? PageRoute.INVESTMENT_OPTIONS : ""}
            data-disabled={!isCampaignActive}
            disabled={!isCampaignActive}
            state={{
              investedAmount,
              investmentGoal,
              campaignId: campaign?.id,
            }}
            w="100%"
            radius="xl"
            size="lg"
            mt="md"
            data-testid="investBtn"
          >
            {t("campaign.card.invest")}
          </Button>
        </Tooltip>
      </Paper>
    </Group>
  );
};

export default StatsCard;
