import { Link } from "react-router-dom";
import classes from "./NavLinks.module.css";
import { useTranslation } from "react-i18next";
import { PageRoute } from "../PageRoute";

const NavLinks = () => {
  const { t } = useTranslation();
  return (
    <>
      <Link to="/#campaigns" className={classes.link}>
        {t("navigation.projects")}
      </Link>
      <Link to={PageRoute.ABOUT_US} className={classes.link}>
        {t("navigation.aboutUs")}
      </Link>
      <Link to={PageRoute.HOW_IT_WORKS} className={classes.link}>
        {t("navigation.howItWorks")}
      </Link>
    </>
  );
};

export default NavLinks;
