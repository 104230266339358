import { useNavigate } from "react-router-dom";
import {
  showErrorNotification,
  showGraphQLErrorNotification,
} from "../../utils";
import PaymentMethodForm from "./PaymentMethodForm";
import { useMutation, useQuery } from "@apollo/client";
import { ErrorResult } from "../../gql/graphql";
import {
  addPaymentToOrderMutationDocument,
  createPaymentIntentMutationDocument,
  activeOrderQueryDocument,
  transitionToStateMutationDocument,
} from "../../api/order";
import { PageRoute } from "../PageRoute";
import { Skeleton } from "@mantine/core";

const PaymentMethodFormContainer = () => {
  const navigate = useNavigate();

  const activeOrderQuery = useQuery(activeOrderQueryDocument);

  const [createPaymentIntent, createPaymentIntentRes] = useMutation(
    createPaymentIntentMutationDocument,
    {
      onCompleted: (result) => {
        navigate(PageRoute.CARD_PAYMENT, {
          state: {
            clientSecret: result.createStripePaymentIntent,
            orderCode: activeOrderQuery.data?.activeOrder?.code,
          },
        });
      },
      onError: (error) => {
        showErrorNotification(error.message);
      },
    }
  );

  const [transitionOrderToState, transitionOrderToStateRes] = useMutation(
    transitionToStateMutationDocument,
    {
      onCompleted: (result) => {
        if (result.transitionOrderToState?.__typename === "Order") {
          console.log("Transitioned to ArrangingPayment");
        } else {
          showGraphQLErrorNotification(
            result.transitionOrderToState as ErrorResult
          );
        }
      },
      onError: (error) => {
        showErrorNotification(error.message);
      },
    }
  );

  const [addPaymentToOrder, addPaymentToOrderRes] = useMutation(
    addPaymentToOrderMutationDocument,
    {
      onCompleted: (result) => {
        if (result.addPaymentToOrder?.__typename === "Order") {
          console.log("Added payment to order.");
        } else {
          showGraphQLErrorNotification(result.addPaymentToOrder as ErrorResult);
        }
      },
      onError: (error) => {
        showErrorNotification(error.message);
      },
    }
  );

  const onSubmit = async (paymentMethod: string) => {
    const orderState = activeOrderQuery.data?.activeOrder?.state;

    if (orderState !== "ArrangingPayment") {
      await transitionOrderToState({
        variables: {
          state: "ArrangingPayment",
        },
      });
    }

    if (paymentMethod === "creditCard") {
      createPaymentIntent();
    } else {
      const addPaymentToOrderRes = await addPaymentToOrder({
        variables: {
          input: {
            metadata: {
              campaignId:
                activeOrderQuery.data?.activeOrder?.customFields?.campaignId,
            },
            method: "wire-transfer",
          },
        },
      });
      if (
        addPaymentToOrderRes.data?.addPaymentToOrder?.__typename === "Order"
      ) {
        navigate(
          PageRoute.INVESTMENT_CONFIRMATION.replace(
            ":orderCode",
            activeOrderQuery.data?.activeOrder?.code ?? ""
          )
        );
      }
    }
  };

  if (activeOrderQuery.loading) return <Skeleton h={400} />;

  return (
    <PaymentMethodForm
      onSubmit={onSubmit}
      isLoading={
        transitionOrderToStateRes.loading ||
        createPaymentIntentRes.loading ||
        addPaymentToOrderRes.loading
      }
    />
  );
};

export default PaymentMethodFormContainer;
