import { Container, Space } from "@mantine/core";
import UserNavbar from "../components/UserNavbar/UserNavbar";
import MyInvestmentsContainer from "../components/MyInvestments/MyInvestments.container";

const MyInvestmentsPage = () => (
  <>
    <Space h="72" />
    <Container size="xl">
      <UserNavbar>
        <MyInvestmentsContainer />
      </UserNavbar>
    </Container>
  </>
);

export default MyInvestmentsPage;
