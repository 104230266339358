import { ReactNode, forwardRef } from 'react';
import { Paper } from '@mantine/core';
import styles from './InvestmentOptions.module.css';

type Props = {
  selected: boolean;
  onClick: () => void;
  packageClassName: string;
  children: ReactNode;
};

const InvestmentOption = forwardRef<HTMLDivElement, Props>(
  ({ selected, onClick, packageClassName, children }, ref) => (
    <Paper
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={ref}
      withBorder
      shadow="md"
      w="100%"
      p="xl"
      className={`${styles.card} ${packageClassName} ${
        selected ? styles.selected : ''
      }`}
      onClick={onClick}
      tabIndex={0}
    >
      {children}
    </Paper>
  )
);

export default InvestmentOption;
