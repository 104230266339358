import { FC } from "react";
import {
  Title,
  Text,
  Group,
  Divider,
  Grid,
  Badge,
  rem,
  Stack,
  Box,
  Tooltip,
  Center,
  SimpleGrid,
  Paper,
} from "@mantine/core";
import {
  IconLeaf,
  IconChartTreemap,
  IconCalendar,
  IconUsers,
  IconMapPin,
  IconInfoCircle,
} from "@tabler/icons-react";
import classes from "./CampaignDetail.module.css";
import { ProductsQuery, CampaignQuery } from "../../gql/graphql";
import StatsCard from "../StatsCard/StatsCard";
import VendureImage from "../VendureImage";
import { LineChart } from "@mantine/charts";
import { useTranslation } from "react-i18next";
import { calculateDaysUntilHarvest } from "../CampaignCard/utils";
import { formatCurrency } from "../../utils";
import TableHarvestPlan from "../TableHarvestPlan/TableHarvestPlan";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const colorPairs: any = {
  blue: { actual: "blue.3", predicted: "blue.9" },
  green: { actual: "green.3", predicted: "green.9" },
  orange: { actual: "orange.3", predicted: "orange.9" },
  teal: { actual: "teal.3", predicted: "teal.9" },
  violet: { actual: "violet.3", predicted: "violet.9" },
  red: { actual: "red.3", predicted: "red.9" },
  cyan: { actual: "cyan.3", predicted: "cyan.9" },
  lime: { actual: "lime.3", predicted: "lime.9" },
  grape: { actual: "grape.3", predicted: "grape.9" },
  indigo: { actual: "indigo.3", predicted: "indigo.9" },
};

interface ChartDataEntry {
  date: number;
  [key: string]: number | string;
}

type Props = {
  campaign: CampaignQuery["campaign"];
  products: ProductsQuery["products"];
};

const CampaignDetail: FC<Props> = ({ campaign, products }) => {
  const { t, i18n } = useTranslation();

  const icon = <IconMapPin style={{ width: rem(12), height: rem(12) }} />;

  const harvestDate = new Date(campaign?.harvestDate);
  const daysUntilHarvest = calculateDaysUntilHarvest(harvestDate);

  const investedAmountFormatted = formatCurrency(campaign?.investedAmount ?? 0);
  const investmentGoalFormatted = formatCurrency(campaign?.goal ?? 0);

  const dataMap: Map<number, ChartDataEntry> = new Map();

  products.items.forEach((product) => {
    product.cropObservations.forEach((observation) => {
      const date = observation.year;
      const keySuffix = `Yield${observation.isPrediction ? "Predicted" : ""}${product.id}`;

      if (!dataMap.has(date)) {
        dataMap.set(date, { date });
      }
      dataMap.get(date)![keySuffix] = observation.value;
    });
  });

  const chartData: ChartDataEntry[] = Array.from(dataMap.values());

  // Array of color family keys to cycle through
  const colorKeys = Object.keys(colorPairs);

  // Dynamically generate series for the LineChart
  const series = products.items.flatMap((product, index) => {
    // Cycle through colorKeys using modulo to avoid out-of-bounds errors
    const colorKey = colorKeys[index % colorKeys.length];
    const colors = colorPairs[colorKey];

    return [
      {
        name: `Yield${product.id}`,
        label: `${product.cropObservations[0].item} (100 g/ha)`,
        color: colors.actual,
      },
      {
        name: `YieldPredicted${product.id}`,
        label: `Predicted ${product.cropObservations[0].item} (100 g/ha)`,
        color: colors.predicted,
      },
    ];
  });

  return (
    <>
      <Title order={1} mb="lg">
        {campaign?.title}
      </Title>

      <Grid gutter="xl">
        <Grid.Col span={{ base: 12, sm: 8 }}>
          {campaign?.image && (
            <VendureImage
              preview={campaign.image.preview}
              preset="large"
              alt={campaign?.seller?.name ?? ""}
              radius="md"
              fit="cover"
              h="100%"
            />
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <Stack gap="xl">
            <Stack>
              <Badge variant="filled" leftSection={icon}>
                {campaign?.location}
              </Badge>
              <Text>{campaign?.description}</Text>
            </Stack>
            <Stack gap="xl">
              <Stack gap={2}>
                <Text fz="sm" c="dimmed" className={classes.label}>
                  {t("campaign.card.basicInfo")}
                </Text>

                <Group>
                  <Center>
                    <IconUsers
                      size="1.05rem"
                      className={classes.icon}
                      stroke={1.5}
                    />
                    <Text size="xs">
                      {t("campaign.card.employee", {
                        count: campaign?.numOfEmployees ?? 1,
                      })}
                    </Text>
                  </Center>
                  {campaign?.farmArea && (
                    <Center>
                      <IconChartTreemap
                        size="1.05rem"
                        className={classes.icon}
                        stroke={1.5}
                      />
                      <Text size="xs">
                        {t("campaign.card.hectares", {
                          area: campaign.farmArea,
                        })}
                      </Text>
                    </Center>
                  )}
                  {campaign?.isOrganic && (
                    <Center>
                      <IconLeaf
                        size="1.05rem"
                        className={classes.icon}
                        stroke={1.5}
                      />
                      <Text size="xs">{t("campaign.card.organicMethods")}</Text>
                    </Center>
                  )}
                </Group>
              </Stack>

              <Stack gap={0}>
                <Text fz="sm" c="dimmed" className={classes.label}>
                  {t("campaign.card.harvestDate")}
                </Text>
                <Group gap={1}>
                  <IconCalendar size="1rem" className={classes.icon} />
                  <Text size="sm">
                    {harvestDate.toLocaleDateString(i18n.language)} (
                    {t("campaign.card.daysUntilHarvest", {
                      days: daysUntilHarvest,
                    })}
                    )
                  </Text>
                </Group>
              </Stack>
            </Stack>
          </Stack>
        </Grid.Col>
      </Grid>

      <SimpleGrid
        spacing={48}
        mt={48}
        mb={32}
        cols={{
          base: 1,
          sm: 2,
        }}
      >
        <Group flex={1} align="flex-start">
          <Paper withBorder radius="md" p="xl" bg="gray.0" flex={1}>
            <Group gap="xl" justify="space-between">
              <div>
                <Text fz="xl" fw={700} style={{ lineHeight: 1 }}>
                  {investedAmountFormatted}
                </Text>
                <Text
                  fz="md"
                  c="dimmed"
                  fw={500}
                  style={{ lineHeight: 1 }}
                  mt={3}
                >
                  {t("campaign.card.raised")}
                </Text>
              </div>

              <div>
                <Text fz="xl" fw={700} style={{ lineHeight: 1 }}>
                  {investmentGoalFormatted}
                </Text>
                <Text
                  fz="md"
                  c="dimmed"
                  fw={500}
                  style={{ lineHeight: 1 }}
                  mt={3}
                >
                  {t("campaign.card.goal")}
                </Text>
              </div>

              <div>
                <Text fz="xl" fw={700} style={{ lineHeight: 1 }}>
                  {campaign?.totalInvestors}
                </Text>
                <Text
                  fz="md"
                  c="dimmed"
                  fw={500}
                  style={{ lineHeight: 1 }}
                  mt={3}
                >
                  {t("campaign.card.investors")}
                </Text>
              </div>
            </Group>
          </Paper>
        </Group>
        <StatsCard campaign={campaign} />
      </SimpleGrid>

      <TableHarvestPlan products={products} campaign={campaign} />

      <Box mt={64} mb={64}>
        <Title order={2} mt="xl">
          {t("campaign.detail.predictionTitle")}
        </Title>
        <Group gap={96} align="flex-start">
          <LineChart
            flex={0.5}
            h={300}
            maw={600}
            mt="xl"
            data={chartData}
            dataKey="date"
            series={series}
            curveType="natural"
            withLegend
            connectNulls
          />
          <Stack flex={0.5} mt="xl">
            <Text fw="500">{t("campaign.detail.predictionDesc")}</Text>
            <Group gap={4}>
              <Text>
                {t("campaign.detail.usedModel")} <strong>ARIMA</strong>
              </Text>
              <Tooltip
                label={t("campaign.detail.usedModelTooltip")}
                position="right"
                withArrow
                multiline
                w={220}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <IconInfoCircle size={16} />
                </div>
              </Tooltip>
            </Group>
          </Stack>
        </Group>
      </Box>

      <Divider my="lg" />

      <Title>{t("campaign.detail.storyTitle")}</Title>
      <Text
        mt="lg"
        mb="lg"
        dangerouslySetInnerHTML={{ __html: campaign?.storyText ?? "" }}
      ></Text>
    </>
  );
};

export default CampaignDetail;
