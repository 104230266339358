import { Grid, Skeleton } from "@mantine/core";
import { allCampaignsQueryDocument } from "../../api/campaign";
import { useQuery } from "@apollo/client";
import CampaignList from "./CampaignList";

const CampaignListContainer = () => {
  const { loading, error, data } = useQuery(allCampaignsQueryDocument);

  if (loading) {
    <>
      <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
        <Skeleton height={500} />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
        <Skeleton height={500} />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
        <Skeleton height={500} />
      </Grid.Col>
    </>;
  }

  if (error) {
    return <div>Error</div>;
  }

  return <CampaignList campaigns={data?.campaigns} />;
};

export default CampaignListContainer;
