import { Button, Group, Paper, TextInput, Title } from "@mantine/core";
import { FC } from "react";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";

export type ContactDetailsFormValuesGuest = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  city: string;
  zip: string;
};

type Props = {
  isLoading: boolean;
  onSubmit: (values: ContactDetailsFormValuesGuest) => void;
};

const ContactDetailsFormGuest: FC<Props> = ({ isLoading, onSubmit }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "checkout.contactDetailsForm",
  });

  const form = useForm<ContactDetailsFormValuesGuest>({
    initialValues: {
      emailAddress: "",
      firstName: "",
      lastName: "",
      street: "",
      streetNumber: "",
      city: "",
      zip: "",
    },

    validate: {
      emailAddress: (value) =>
        /^\S+@\S+$/.test(value) ? null : t("emailInvalid"),
    },
  });

  return (
    <Paper withBorder shadow="md" p={30} radius="md" flex={1}>
      <Title order={4} mb="md">
        {t("title")}
      </Title>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          label={t("emailLabel")}
          placeholder={t("emailPlaceholder")}
          required
          data-testid="email"
          {...form.getInputProps("emailAddress")}
        />
        <TextInput
          label={t("firstNameLabel")}
          placeholder={t("firstNamePlaceholder")}
          required
          mt="md"
          data-testid="firstName"
          {...form.getInputProps("firstName")}
        />
        <TextInput
          label={t("lastNameLabel")}
          placeholder={t("lastNamePlaceholder")}
          required
          mt="md"
          data-testid="lastName"
          {...form.getInputProps("lastName")}
        />
        <Group>
          <TextInput
            label={t("streetLabel")}
            placeholder={t("streetPlaceholder")}
            required
            mt="md"
            style={{ flex: 1 }}
            data-testid="street"
            {...form.getInputProps("street")}
          />
          <TextInput
            label={t("streetNumberLabel")}
            placeholder={t("streetNumberPlaceholder")}
            required
            mt="md"
            style={{ flex: 0.7 }}
            data-testid="streetNumber"
            {...form.getInputProps("streetNumber")}
          />
        </Group>

        <TextInput
          label={t("cityLabel")}
          placeholder={t("cityPlaceholder")}
          required
          mt="md"
          data-testid="city"
          {...form.getInputProps("city")}
        />
        <TextInput
          label={t("zipLabel")}
          placeholder={t("zipPlaceholder")}
          required
          mt="md"
          data-testid="postCode"
          {...form.getInputProps("zip")}
        />

        <Button
          type="submit"
          fullWidth
          mt="xl"
          loading={isLoading}
          data-testid="submitBtn"
        >
          {t("submitButton")}
        </Button>
      </form>
    </Paper>
  );
};

export default ContactDetailsFormGuest;
