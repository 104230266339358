import { FC } from "react";
import { Stack, Title } from "@mantine/core";
import { ActiveCustomerQuery } from "../../gql/graphql";
import InvestmentCardContainer from "../InvestmentCard/InvestmentCard.container";
import { useTranslation } from "react-i18next";

type Props = {
  investments: ActiveCustomerQuery["activeCustomer"];
};

const MyInvestments: FC<Props> = ({ investments }) => {
  const { t } = useTranslation();
  return (
    <Stack gap="xl" flex={1}>
      <Title order={2}>{t("myInvestments")}</Title>
      {investments?.orders.items.map((investment) => (
        <InvestmentCardContainer key={investment.id} investment={investment} />
      ))}
    </Stack>
  );
};

export default MyInvestments;
