export const calculateDaysUntilHarvest = (harvestDate: Date) => {
  const today = new Date();
  const harvest = new Date(harvestDate);
  const difference = harvest.getTime() - today.getTime();
  return Math.ceil(difference / (1000 * 3600 * 24));
};

export const calculateDaysRemaining = (
  launchDate: string,
  duration: number
) => {
  const startDate = new Date(launchDate);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + duration);

  const today = new Date();
  const remainingTime = endDate.getTime() - today.getTime();
  const remainingDays = Math.ceil(remainingTime / (1000 * 3600 * 24));

  return remainingDays > 0 ? remainingDays : 0;
};

export const calculateDateDifference = (targetDate: Date, isStart = false) => {
  const today = new Date();
  const timeDiff = isStart
    ? targetDate.getTime() - today.getTime()
    : today.getTime() - targetDate.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return daysDiff > 0 ? daysDiff : 0;
};
