import { graphql } from "../gql";

export const allCampaignsQueryDocument = graphql(`
  query campaigns {
    campaigns {
      id
      goal
      investedAmount
      harvestDate
      status
      title
      description
      location
      launchDate
      durationDays
      storyText
      isOrganic
      numOfEmployees
      farmArea
      totalInvestors
      image {
        id
        preview
      }
      seller {
        id
        name
        customFields {
          channelToken
        }
      }
    }
  }
`);

export const campaignQueryDocument = graphql(`
  query campaign($id: ID!) {
    campaign(id: $id) {
      id
      goal
      investedAmount
      harvestDate
      status
      title
      description
      location
      launchDate
      durationDays
      storyText
      isOrganic
      numOfEmployees
      farmArea
      totalInvestors
      image {
        id
        preview
      }
      seller {
        id
        name
      }
    }
  }
`);
