import { Avatar, Group, Stack, Title, Text, Divider } from "@mantine/core";
import { CampaignQuery, GetActiveOrderQuery } from "../../gql/graphql";
import { FC } from "react";
import { IconApple, IconBuildingStore } from "@tabler/icons-react";
import { formatCurrency } from "../../utils";
import { useTranslation } from "react-i18next";

type Props = {
  investment: GetActiveOrderQuery["activeOrder"];
  campaign: CampaignQuery["campaign"];
};

const CheckoutSummary: FC<Props> = ({ investment, campaign }) => {
  const { t, i18n } = useTranslation();

  const harvestDate = new Date(campaign?.harvestDate ?? "");
  const formattedHarvestDate = harvestDate.toLocaleDateString(i18n.language);

  return (
    <Stack p="lg" flex={1}>
      <Title order={4} mb="xs">
        {t("summary")}
      </Title>
      <Group gap="xs">
        <IconBuildingStore size="1.2rem" stroke={1} />
        <Text size="sm" fw={500}>
          {campaign?.seller.name}
        </Text>
      </Group>

      <Group justify="space-between">
        <Text size="xs" fw={400}>
          {t("expectedHarvest")}
        </Text>
        <Text size="xs" fw={600} c="dimmed">
          {formattedHarvestDate}
        </Text>
      </Group>

      <Divider />
      <Title order={6}>{t("produce")}</Title>

      {investment?.lines.map((line) => (
        <Group wrap="nowrap">
          <Avatar size={40} radius={40}>
            <IconApple size="1.5rem" />
          </Avatar>
          <Stack gap={0} flex={1}>
            <Text fz="sm" fw={500}>
              {line.productVariant.product.name}
            </Text>
            <Text c="dimmed" fz="xs">
              {(line.productVariant.product.customFields?.ratio ?? 0) * 100}%
            </Text>
          </Stack>
          <Text size="sm" fw={500} style={{ alignSelf: "flex-end" }}>
            {formatCurrency(line.linePriceWithTax)}
          </Text>
        </Group>
      ))}
      <Divider mt={12} />
      <Group justify="space-between">
        <Text fz="sm" fw={500}>
          {t("total")}
        </Text>
        <Text fz="sm" fw={500}>
          {formatCurrency(investment?.totalWithTax ?? 0)}
        </Text>
      </Group>
    </Stack>
  );
};

export default CheckoutSummary;
