import { Button, Container, Overlay, Text, Title } from "@mantine/core";
import cx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PageRoute } from "../PageRoute";
import classes from "./Hero.module.css";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <Overlay color="#000" opacity={0.99} zIndex={1} />

      <Container size="xl">
        <div className={classes.inner}>
          <Title className={classes.title}>
            {t("hero.title")}{" "}
            <Text component="span" inherit className={classes.highlight}>
              {t("hero.highlight")}
            </Text>
          </Title>

          <Container size={640}>
            <Text size="lg" className={classes.description}>
              {t("hero.description")}
            </Text>
          </Container>

          <div className={classes.controls}>
            <Button
              component={Link}
              to="/#campaigns"
              className={classes.control}
              variant="white"
              size="lg"
            >
              {t("hero.viewProjects")}
            </Button>
            <Button
              component={Link}
              to={PageRoute.HOW_IT_WORKS}
              className={cx(classes.control, classes.secondaryControl)}
              size="lg"
            >
              {t("hero.howItWorks")}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
