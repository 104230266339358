import { FC } from "react";
import { Divider, Group } from "@mantine/core";
import { ActiveCustomerQuery } from "../../gql/graphql";
import NavLinks from "../NavLinks/NavLinks";
import ProfileButton from "../ProfileButton/ProfileButton";
import SignInButton from "./SignInButton";
import SignUpButton from "./SignUpButton";

type Props = {
  activeCustomer?: ActiveCustomerQuery["activeCustomer"];
};

const Navbar: FC<Props> = ({ activeCustomer }) => (
  <>
    <NavLinks />
    <Divider my="sm" />

    {activeCustomer ? (
      <div>
        <ProfileButton user={activeCustomer} />
      </div>
    ) : (
      <Group justify="center" grow pb="xl" px="md">
        <SignInButton />
        <SignUpButton />
      </Group>
    )}
  </>
);

export default Navbar;
