import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

const httpLink = createUploadLink({
  uri: import.meta.env.VITE_API_URL,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  const vendureToken = localStorage.getItem("vendure-token");
  const authHeaders = vendureToken ? { "vendure-token": vendureToken } : {};

  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});
export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});
