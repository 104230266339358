import { FC } from "react";
import { Avatar, Table, Group, Text } from "@mantine/core";

import { IconApple } from "@tabler/icons-react";
import { ActiveCustomerQuery } from "../gql/graphql";
import { formatCurrency } from "../utils";

type Props = {
  productLines?: NonNullable<
    ActiveCustomerQuery["activeCustomer"]
  >["orders"]["items"][0]["lines"];
};

export const ProduceTable: FC<Props> = ({ productLines }) => {
  const totalQuantity = productLines?.reduce(
    (acc, item) => acc + item.quantity,
    0
  );
  console.log(totalQuantity);

  const rows = productLines?.map((line) => {
    const productRatio = (line.quantity / (totalQuantity ?? 0)) * 100;
    const product = line.productVariant.product;
    return (
      <Table.Tr key={line.id}>
        <Table.Td>
          <Group gap="sm">
            <Avatar size={40} radius={40}>
              <IconApple size="1.5rem" />
            </Avatar>
          </Group>
        </Table.Td>

        <Table.Td>
          <div>
            <Text fz="md" fw={500}>
              {product.name}
            </Text>
            <Text c="dimmed" fz="xs">
              {product.description}
            </Text>
          </div>
        </Table.Td>

        <Table.Td>
          <Text fz="xs" c="dimmed">
            Ratio
          </Text>
          <Text fz="sm">{productRatio}%</Text>
        </Table.Td>

        <Table.Td>
          <Text fw={600} size="xl">
            {formatCurrency(line.linePriceWithTax)}
          </Text>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <Table.ScrollContainer minWidth={200}>
      <Table verticalSpacing="sm" horizontalSpacing="lg">
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};
