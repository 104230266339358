import { useQuery } from "@apollo/client";
import { Skeleton } from "@mantine/core";
import { FC } from "react";
import { campaignQueryDocument } from "../../api/campaign";
import { ActiveCustomerQuery } from "../../gql/graphql";
import InvestmentCard from "./InvestmentCard";

type Props = {
  investment: NonNullable<
    ActiveCustomerQuery["activeCustomer"]
  >["orders"]["items"][0];
};

const InvestmentCardContainer: FC<Props> = ({ investment }) => {
  const { data, loading, error } = useQuery(campaignQueryDocument, {
    variables: {
      id: investment.customFields?.campaignId ?? "",
    },
  });

  if (loading) {
    return <Skeleton height={200} />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <InvestmentCard investment={investment} campaign={data?.campaign} />;
};

export default InvestmentCardContainer;
