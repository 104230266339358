import { Container, Title, Text, Paper, Button, Stack } from "@mantine/core";
import classes from "./SignInPage.module.css";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SignUpConfirmationPage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  return (
    <Container size={420} my={40}>
      <Title ta="center" className={classes.title} data-testid="title">
        Účet {state?.isAdmin ? "predajcu" : "investora"} bol úspešne vytvorený
      </Title>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Stack align="center">
          <Text size="md" ta="center" mt={5} data-testid="text">
            Na vašu e-mailovú adresu {state?.email ?? "<empty>"} sme vám zaslali
            odkaz na overenie účtu.
          </Text>
          <Button
            component="a"
            target="_blank"
            href="https://mail.google.com/"
            size="sm"
          >
            {t("openGmail")}
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
};

export default SignUpConfirmationPage;
