import { useMutation } from "@apollo/client";
import {
  Button,
  Container,
  Loader,
  Paper,
  PasswordInput,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAlertCircle, IconCheck } from "@tabler/icons-react";
import { Link, useSearchParams } from "react-router-dom";
import {
  activeCustomerQueryDocument,
  verifyUserMutationDocument,
} from "../api/customer";
import { client } from "../app/apolloClient";
import { PageRoute } from "../components/PageRoute";
import { showErrorNotification, showGraphQLErrorNotification } from "../utils";
import classes from "./SignInPage.module.css";
import { useTranslation } from "react-i18next";

type ProvidePasswordFormValues = {
  password: string;
  repeatPassword: string;
};

const VerifyUserPageWithPassword = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const form = useForm<ProvidePasswordFormValues>({
    initialValues: {
      password: "",
      repeatPassword: "",
    },

    validate: {
      password: (value) =>
        value.length >= 4 ? null : t("signUp.shortPassword"),
      repeatPassword: (value, values) =>
        value === values.password ? null : t("signUp.passwordsDoNotMatch"),
    },
  });

  const [verifyUser, { loading, data }] = useMutation(
    verifyUserMutationDocument,
    {
      onCompleted: (result) => {
        if (result.verifyCustomerAccount.__typename === "CurrentUser") {
          client.refetchQueries({ include: [activeCustomerQueryDocument] });
        } else if (
          result.verifyCustomerAccount.__typename === "MissingPasswordError"
        ) {
          // todo:
        } else {
          showGraphQLErrorNotification(result.verifyCustomerAccount);
        }
      },
      onError: (error) => {
        showErrorNotification(error.message);
      },
    }
  );

  const handleSubmit = (values: ProvidePasswordFormValues) => {
    if (!searchParams.has("token")) {
      showErrorNotification(t("verifyUser.tokenMissing"));
      return;
    }
    verifyUser({
      variables: {
        token: searchParams.get("token") as string,
        password: values.password,
      },
    });
  };

  return (
    <Container size={500} my={40}>
      <Title ta="center" className={classes.title}>
        {t("verifyUser.verifyAccountTitle")}
      </Title>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Stack align="center">
          {!loading && !data && (
            <form
              onSubmit={form.onSubmit(handleSubmit)}
              style={{ width: "100%" }}
            >
              <PasswordInput
                label={t("signUp.password")}
                placeholder={t("signUp.enterPassword")}
                required
                mt="md"
                data-testid="password"
                {...form.getInputProps("password")}
              />
              <PasswordInput
                label={t("signUp.repeatPassword")}
                placeholder={t("signUp.repeatPasswordPlaceholder")}
                required
                mt="md"
                data-testid="repeatPassword"
                {...form.getInputProps("repeatPassword")}
              />
              <Button
                type="submit"
                fullWidth
                mt="xl"
                loading={loading}
                data-testid="submitBtn"
              >
                {t("verifyUser.verifyBtn")}
              </Button>
            </form>
          )}
          {loading && <Loader />}
          {!loading &&
            data?.verifyCustomerAccount.__typename === "CurrentUser" && (
              <>
                <IconCheck size={50} color="green" />

                <Text size="md" ta="center" mt={5} data-testid="successTitle">
                  {t("verifyUser.accountVerified")}
                </Text>
                <Button
                  component={Link}
                  to={PageRoute.HOME}
                  data-testid="homeBtn"
                >
                  {t("verifyUser.goToHomePage")}
                </Button>
              </>
            )}
          {!loading &&
            data &&
            data.verifyCustomerAccount.__typename !== "CurrentUser" && (
              <>
                <IconAlertCircle size={50} color="red" />
                <Text size="md" ta="center" mt={5}>
                  {t("verifyUser.verificationFailed")}{" "}
                  {data.verifyCustomerAccount.message}
                </Text>
              </>
            )}
        </Stack>
      </Paper>
    </Container>
  );
};

export default VerifyUserPageWithPassword;
