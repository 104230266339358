import { FC } from "react";
import {
  Stack,
  Group,
  Button,
  Title,
  Text,
  Pill,
  lighten,
  darken,
  Badge,
  Table,
  Tooltip,
} from "@mantine/core";
import styles from "./InvestmentOptions.module.css";
import { ProductVariant, VariantWithRatio } from "../../types";
import { IconInfoCircle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

type Props = {
  selected: boolean;
  amount: number;
  title: string;
  label: string;
  color: string;
  products: ProductVariant[];
  onInvestClick: (variants: VariantWithRatio[]) => () => void;
};

const PremadeInvestmentForm: FC<Props> = ({
  selected,
  title,
  amount,
  label,
  color,
  products,
  onInvestClick,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "campaign.premadeInvestmentForm",
  });

  const initialRatios = (() => {
    const itemCount = products.length;
    const baseRatio = Math.floor(100 / itemCount / 5) * 5; // Ensures it's a multiple of 5
    const totalBaseRatio = baseRatio * itemCount;
    const remainder = 100 - totalBaseRatio;

    // Distribute the base ratio to each item and add the remainder to the last item
    return Array(itemCount)
      .fill(baseRatio)
      .map((ratio, index) => ratio + (index === itemCount - 1 ? remainder : 0));
  })();

  return (
    <Stack gap="xl">
      <Group justify="space-between">
        <Title order={2} c={darken(color, 0.3)}>
          {title}
        </Title>
        <Group>
          <Pill size="lg" c={darken(color, 0.15)} bg={lighten(color, 0.85)}>
            {label}
          </Pill>
          <div
            style={{ backgroundColor: color }}
            className={styles.packageIndicator}
          ></div>
        </Group>
      </Group>
      {selected && (
        <Stack gap={8}>
          <Title order={5}>{t("youReceive")}</Title>
          <Group wrap="nowrap" align="flex-end" gap={64}>
            <Table verticalSpacing="xs">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>{t("produceName")}</Table.Th>
                  <Table.Th>
                    <Group>
                      {t("investmentRatio")}{" "}
                      <Tooltip
                        label="Investičný pomer určuje podiel vašej investície do každého typu produkcie."
                        position="right"
                        withArrow
                        multiline
                        w={220}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {" "}
                          <IconInfoCircle size={16} />
                        </div>
                      </Tooltip>
                    </Group>
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {products.map((product, index) => (
                  <Table.Tr key={product.name}>
                    <Table.Td>
                      <Text>{product.name}</Text>
                    </Table.Td>
                    <Table.Td>
                      <Badge color={color} variant="light" size="lg">
                        {initialRatios[index]} %
                      </Badge>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
            <Group>
              <Button
                w="100%"
                size="md"
                bg={color}
                onClick={onInvestClick(
                  products.map((product) => ({
                    variantId: product.id,
                    stockAmount:
                      (Number(amount) * (100 / products.length)) / 10000,
                  }))
                )}
              >
                {t("invest")} {title}
              </Button>
            </Group>
          </Group>
        </Stack>
      )}
    </Stack>
  );
};

export default PremadeInvestmentForm;
