import { Container, Group, Skeleton, Burger } from "@mantine/core";
import Logo from "../Logo";
import NavLinks from "../NavLinks/NavLinks";
import ProfileButton from "../ProfileButton/ProfileButton";
import { FC } from "react";
import { ActiveCustomerQuery } from "../../gql/graphql";
import SignInButton from "./SignInButton";
import SignUpButton from "./SignUpButton";
import { Link } from "react-router-dom";

type Props = {
  menuOpened: boolean;
  toggleMenu: () => void;
  activeCustomer?: ActiveCustomerQuery["activeCustomer"];
  isLoading?: boolean;
};

const Header: FC<Props> = ({
  menuOpened,
  toggleMenu,
  activeCustomer,
  isLoading,
}) => (
  <Container size="xl" h="100%">
    <Group justify="space-between" h="100%">
      <Link to="/" style={{ height: "100%" }}>
        <Logo />
      </Link>

      <Group h="100%" gap={0} visibleFrom="md">
        <NavLinks />
      </Group>

      <Group visibleFrom="md">
        <Skeleton visible={isLoading}>
          {activeCustomer ? (
            <ProfileButton user={activeCustomer} />
          ) : (
            <Group>
              <SignInButton />
              <SignUpButton />
            </Group>
          )}
        </Skeleton>
      </Group>

      <Burger opened={menuOpened} onClick={toggleMenu} hiddenFrom="md" />
    </Group>
  </Container>
);

export default Header;
