import {
  Badge,
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Group,
  Progress,
  Stack,
  Text,
  rem,
} from "@mantine/core";
import {
  IconCalendar,
  IconChartTreemap,
  IconLeaf,
  IconMapPin,
  IconUsers,
} from "@tabler/icons-react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { CampaignStatus, CampaignsQuery } from "../../gql/graphql";
import { formatCurrency } from "../../utils";
import { PageRoute } from "../PageRoute";
import VendureImage from "../VendureImage";
import classes from "./CampaignCard.module.css";
import { useTranslation } from "react-i18next";
import {
  calculateDaysUntilHarvest,
  calculateDaysRemaining,
  calculateDateDifference,
} from "./utils";

type Props = {
  campaign: CampaignsQuery["campaigns"][number];
};

const CampaignCard: FC<Props> = ({ campaign }) => {
  const { goal, investedAmount } = campaign;

  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "campaign.card",
  });

  const harvestDate = new Date(campaign.harvestDate);
  const daysUntilHarvest = calculateDaysUntilHarvest(harvestDate);

  const icon = <IconMapPin style={{ width: rem(12), height: rem(12) }} />;

  const progress = (investedAmount / goal) * 100;

  const investedAmountFormatted = formatCurrency(investedAmount);
  const investmentGoalFormatted = formatCurrency(goal);

  const launchDate = new Date(campaign.launchDate);
  const duration = campaign.durationDays;
  const endDate = new Date(launchDate);
  endDate.setDate(launchDate.getDate() + duration);

  const daysRemaining = calculateDaysRemaining(campaign.launchDate, duration);
  const daysUntilStart = calculateDateDifference(launchDate, true);

  const onInvestClick = () => {
    const channelToken = campaign?.seller?.customFields?.channelToken ?? "";
    localStorage.setItem("vendure-token", channelToken);
  };

  const getCampaignStatus = () => {
    if (campaign.status === CampaignStatus.Active) {
      return (
        <Badge variant="light" size="md">
          {t("daysRemaining", { days: daysRemaining })}
        </Badge>
      );
    }
    if (campaign.status === CampaignStatus.Scheduled) {
      return (
        <Badge variant="light" size="md" color="blue">
          {t("startsIn", { days: daysUntilStart })}
        </Badge>
      );
    }
    if (campaign.status === CampaignStatus.Ended) {
      return (
        <Badge variant="light" size="md" color="gray">
          {t("ended")}
        </Badge>
      );
    }

    return null;
  };

  const campaignStatusBadge = getCampaignStatus();

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.imageSection}>
        {campaign.image?.preview && (
          <VendureImage
            preview={campaign.image?.preview}
            preset="medium"
            alt="Campaign image"
          />
        )}
      </Card.Section>

      <Flex flex={1}>
        <Stack mt="md" gap="xs" align="flex-start">
          <Badge size="sm" variant="filled" leftSection={icon}>
            {campaign.location}
          </Badge>
          <div>
            <Text fw={500}>{campaign.title}</Text>
            <Text fz="xs" c="dimmed" lineClamp={3}>
              {campaign.description}
            </Text>
          </div>
        </Stack>
      </Flex>

      <Card.Section className={classes.section} mt="md">
        <Text fz="sm" c="dimmed" className={classes.label}>
          {t("basicInfo")}
        </Text>

        <Group gap={8} mb={-8}>
          <Center>
            <IconUsers size="1.05rem" className={classes.icon} stroke={1.5} />
            <Text size="xs">
              {t("employee", {
                count: campaign.numOfEmployees ?? 1,
              })}
            </Text>
          </Center>
          {campaign.farmArea && (
            <Center>
              <IconChartTreemap
                size="1.05rem"
                className={classes.icon}
                stroke={1.5}
              />
              <Text size="xs">
                {t("hectares", {
                  area: campaign.farmArea,
                })}
              </Text>
            </Center>
          )}
          {campaign.isOrganic && (
            <Center>
              <IconLeaf size="1.05rem" className={classes.icon} stroke={1.5} />
              <Text size="xs">{t("organicMethods")}</Text>
            </Center>
          )}
        </Group>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Text fz="sm" c="dimmed" className={classes.label}>
          {t("harvestDate")}
        </Text>
        <Group gap={1}>
          <IconCalendar size="1rem" className={classes.icon} />
          <Text size="sm">
            {harvestDate.toLocaleDateString(i18n.language)} (
            {t("daysUntilHarvest", { days: daysUntilHarvest })})
          </Text>
        </Group>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Group justify="space-between">
          <Text
            size="sm"
            fw={500}
          >{`${investedAmountFormatted} z ${investmentGoalFormatted}`}</Text>

          {campaignStatusBadge}
        </Group>
        <Group justify="center" mt="xs" gap="xs">
          <Progress value={progress} flex={1} />
          <Text size="xs" fw={600} c="gray.6">
            {Math.round(progress)}%
          </Text>
        </Group>
      </Card.Section>

      <Card.Section>
        <Divider />
      </Card.Section>

      <Card.Section className={classes.section}>
        <Group justify="space-between" wrap="nowrap">
          <Group gap="xl">
            <div>
              <Text fz="lg" fw={700} style={{ lineHeight: 1 }}>
                {investedAmountFormatted}
              </Text>
              <Text
                fz="sm"
                c="dimmed"
                fw={500}
                style={{ lineHeight: 1 }}
                mt={3}
              >
                {t("raised")}
              </Text>
            </div>

            <div>
              <Text fz="lg" fw={700} style={{ lineHeight: 1 }}>
                {campaign.totalInvestors}
              </Text>
              <Text
                fz="sm"
                c="dimmed"
                fw={500}
                style={{ lineHeight: 1 }}
                mt={3}
              >
                {t("investors")}
              </Text>
            </div>
          </Group>

          <Button
            component={Link}
            to={PageRoute.CAMPAIGN_DETAIL.replace(":id", campaign.id)}
            radius="xl"
            onClick={onInvestClick}
            data-testid="investBtn"
            maw="150px"
            fullWidth
          >
            {t("invest")}
          </Button>
        </Group>
      </Card.Section>
    </Card>
  );
};

export default CampaignCard;
