import { graphql } from "../gql";

export const allProductsQueryDocument = graphql(`
  query products($options: ProductListOptions) {
    products(options: $options) {
      items {
        id
        name
        description
        customFields {
          ratio
        }
        cropObservations {
          itemCode
          item
          year
          value
          isPrediction
        }
        variants {
          id
          name
          sku
          price
          stockLevel
          featuredAsset {
            preview
          }
        }
      }
    }
  }
`);
