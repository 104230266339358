import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  activeCustomerQueryDocument,
  setCustomerForOrderMutationDocument,
  signUpMutationDocument,
} from "../../api/customer";
import {
  elligibleShippingMethodsQueryDocument,
  activeOrderQueryDocument,
  setContactDetailsMutationDocument,
  setShippingMethodMutationDocument,
} from "../../api/order";
import { ErrorResult } from "../../gql/graphql";
import {
  showErrorNotification,
  showGraphQLErrorNotification,
} from "../../utils";
import ContactDetailsFormGuest, {
  ContactDetailsFormValuesGuest,
} from "./ContactDetailsFormGuest";
import ContactDetailsFormRegistered, {
  ContactDetailsFormValuesRegistered,
} from "./ContactDetailsFormRegistered";
import { Skeleton } from "@mantine/core";

const ContactDetailsFormContainer = () => {
  const navigate = useNavigate();

  const activeCustomerQuery = useQuery(activeCustomerQueryDocument);
  const activeCustomer = activeCustomerQuery.data?.activeCustomer;

  const activeOrderQuery = useQuery(activeOrderQueryDocument);

  const [setCustomer, setCustomerResult] = useMutation(
    setCustomerForOrderMutationDocument,
    {
      onCompleted: (result) => {
        if (result.setCustomerForOrder.__typename === "Order") {
          console.log("Customer info set");
        } else {
          showGraphQLErrorNotification(
            result.setCustomerForOrder as ErrorResult
          );
        }
      },
      onError: (error) => {
        console.error(error);
        showErrorNotification(error.message);
      },
    }
  );

  const elligibleShippingMethods = useQuery(
    elligibleShippingMethodsQueryDocument
  );

  console.log(elligibleShippingMethods.data);

  const [setShippingMethod, setShippingMethodResult] = useMutation(
    setShippingMethodMutationDocument,
    {
      onCompleted: (result) => {
        if (result.setOrderShippingMethod.__typename === "Order") {
          console.log("Shipping method set");
          navigate("/payment-method");
        } else {
          showGraphQLErrorNotification(
            result.setOrderShippingMethod as ErrorResult
          );
        }
      },
      onError: (error) => {
        console.error(error);
        showErrorNotification(error.message);
      },
    }
  );

  const [registerUser, registerResult] = useMutation(signUpMutationDocument, {
    onCompleted: (result) => {
      if (result.registerCustomerAccount.__typename === "Success") {
        console.log("Guest customer registered");
      } else {
        showGraphQLErrorNotification(result.registerCustomerAccount);
      }
    },
    onError: (error) => {
      console.error(error);
      showErrorNotification(error.message);
    },
  });

  const [setContactDetails, setContactDetailsResult] = useMutation(
    setContactDetailsMutationDocument,
    {
      onCompleted: (result) => {
        if (result.setOrderShippingAddress.__typename === "Order") {
          console.log("Shipping address set.");
          setShippingMethod({
            variables: {
              id:
                elligibleShippingMethods.data?.eligibleShippingMethods[0].id ??
                "",
            },
          });
        } else {
          showGraphQLErrorNotification(result.setOrderShippingAddress);
        }
      },
      onError: (error) => {
        console.error(error);
        showErrorNotification(error.message);
      },
    }
  );

  const onSubmitGuest = async (values: ContactDetailsFormValuesGuest) => {
    await setCustomer({
      variables: {
        input: {
          emailAddress: values.emailAddress,
          firstName: values.firstName,
          lastName: values.lastName,
        },
      },
    });

    await registerUser({
      variables: {
        input: {
          emailAddress: values.emailAddress,
          firstName: values.firstName,
          lastName: values.lastName,
        },
      },
    });

    await setContactDetails({
      variables: {
        input: {
          fullName: `${values.firstName} ${values.lastName}`,
          streetLine1: `${values.street} ${values.streetNumber}`,
          city: values.city,
          postalCode: values.zip,
          countryCode: "sk",
        },
      },
    });
  };

  const onSubmitRegistered = async (
    values: ContactDetailsFormValuesRegistered
  ) => {
    await setContactDetails({
      variables: {
        input: {
          fullName: `${activeCustomer?.firstName} ${activeCustomer?.lastName}`,
          streetLine1: `${values.street} ${values.streetNumber}`,
          city: values.city,
          postalCode: values.zip,
          countryCode: "sk",
        },
      },
    });
  };

  if (activeOrderQuery.loading) return <Skeleton h={400} />;

  return !activeCustomer ? (
    <ContactDetailsFormGuest
      onSubmit={onSubmitGuest}
      isLoading={
        setCustomerResult.loading ||
        registerResult.loading ||
        setContactDetailsResult.loading ||
        setShippingMethodResult.loading
      }
    />
  ) : (
    <ContactDetailsFormRegistered
      onSubmit={onSubmitRegistered}
      isLoading={
        setContactDetailsResult.loading ||
        setCustomerResult.loading ||
        setShippingMethodResult.loading
      }
    />
  );
};

export default ContactDetailsFormContainer;
