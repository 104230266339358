import { Button } from "@mantine/core";
import { Link } from "react-router-dom";
import { PageRoute } from "../PageRoute";
import { useTranslation } from "react-i18next";

const SignUpButton = () => {
  const { t } = useTranslation();
  return (
    <Button component={Link} to={PageRoute.SIGN_UP}>
      {t("signUpBtn")}
    </Button>
  );
};

export default SignUpButton;
