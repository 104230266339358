import { Container, Title, Text, Paper, Image, Center } from "@mantine/core";

const AboutUsPage = () => {
  return (
    <Container>
      <Title ta="center" mt={30} mb={15}>
        O nás
      </Title>

      <Paper shadow="xs" p="lg">
        <Center>
          <Image
            src="/images/about_us.png"
            mah={500}
            fit="cover"
            alt="Crowdfarming"
          />
        </Center>

        <Text size="md" mt="md">
          Vitajte na našej crowdfarming platforme! Sme odhodlaní podporovať
          malých farmárov a propagovať udržateľné poľnohospodárske praktiky.
          Našou misiou je prepojiť malých farmárov so spotrebiteľmi, ktorí dbajú
          na životné prostredie a pôvod svojich potravín.
        </Text>

        <Text size="md" mt="md">
          Naša platforma bola vytvorená v roku 2024 ako súčasť diplomovej práce
          na Fakulte informatiky a informačných technológií Slovenskej
          technickej univerzity v Bratislave. Cieľom platformy je
          revolucionizovať spôsob, akým premýšľame o farmárčení a spotrebe
          potravín.
        </Text>

        <Text size="md" mt="md">
          Naša platforma ponúka jedinečnú príležitosť pre spotrebiteľov priamo
          investovať a podporovať malých farmárov. To nielen pomáha zlepšiť
          životné podmienky farmárov, ale zároveň zabezpečuje, že môžu
          pokračovať v poľnohospodárstve ekologicky a udržateľným spôsobom.
        </Text>

        <Text size="md" mt="md" ta="center">
          Ďakujeme, že ste súčasťou nášho príbehu.
        </Text>
      </Paper>
    </Container>
  );
};

export default AboutUsPage;
