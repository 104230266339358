import { useQuery } from "@apollo/client";
import { FC } from "react";
import { activeOrderQueryDocument } from "../../api/order";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { Skeleton } from "@mantine/core";

type Props = {
  orderCode: string;
};

const StripeCheckoutFormContainer: FC<Props> = ({ orderCode }) => {
  const { data, error, loading } = useQuery(activeOrderQueryDocument);

  if (loading) {
    <Skeleton height={300} />;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <StripeCheckoutForm
      orderCode={orderCode}
      priceToPay={data?.activeOrder?.totalWithTax}
    />
  );
};

export default StripeCheckoutFormContainer;
