import { Grid } from "@mantine/core";
import { CampaignsQuery } from "../../gql/graphql";
import { FC } from "react";
import CampaignCard from "../CampaignCard/CampaignCard";

type Props = {
  campaigns?: CampaignsQuery["campaigns"];
};

const CampaignList: FC<Props> = ({ campaigns }) =>
  campaigns?.map((campaign) => (
    <Grid.Col
      span={{ base: 12, xs: 6, md: 4 }}
      key={campaign.id}
      data-testid="campaigns"
    >
      <CampaignCard campaign={campaign} />
    </Grid.Col>
  ));

export default CampaignList;
