import { Group, Anchor, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const onLanguageChange = (language: string) => () => {
    i18n.changeLanguage(language);
  };

  return (
    <Group gap={6} mr="lg">
      <Anchor
        size="sm"
        onClick={onLanguageChange("sk")}
        fw={i18n.language === "sk" ? 700 : 400}
      >
        SK
      </Anchor>
      <Text size="sm" c="myColor">
        /
      </Text>
      <Anchor
        size="sm"
        onClick={onLanguageChange("en")}
        fw={i18n.language === "en" ? 700 : 400}
      >
        EN
      </Anchor>
    </Group>
  );
};

export default LanguageSwitcher;
