import { Container, Card, Text, Title } from "@mantine/core";

const HowItWorksPage = () => {
  return (
    <Container size="md" p="lg">
      <Title order={1}>Ako to funguje</Title>

      <Card shadow="sm" padding="lg" style={{ marginTop: "20px" }}>
        <Title order={2}>Krok 1: Registrácia</Title>
        <Text>
          Registrovať sa na našu platformu je jednoduché a rýchle. Stačí vyplniť
          základné informácie a môžete začať.
        </Text>
      </Card>

      <Card shadow="sm" padding="lg" style={{ marginTop: "20px" }}>
        <Title order={2}>Krok 2: Vyberte si farmára</Title>
        <Text>
          Prezrite si profily farmárov a vyberte si, koho chcete podporiť. Každý
          farmár má unikátny príbeh a plány.
        </Text>
      </Card>

      <Card shadow="sm" padding="lg" style={{ marginTop: "20px" }}>
        <Title order={2}>Krok 3: Investujte</Title>
        <Text>
          Investujte do vybraných projektov. Vaša investícia pomôže farmárovi
          nakúpiť potrebné zdroje a technológie.
        </Text>
      </Card>

      <Card shadow="sm" padding="lg" style={{ marginTop: "20px" }}>
        <Title order={2}>Krok 4: Sledujte rast</Title>
        <Text>
          Sledujte, ako vaša investícia pomáha farmárovi a ako rastie jeho
          poľnohospodárstvo.
        </Text>
      </Card>

      <Card shadow="sm" padding="lg" style={{ marginTop: "20px" }}>
        <Title order={2}>Krok 5: Získajte podiel z úrody</Title>
        <Text>Po úspešnom zbere úrody získate podiel z urodených plodín.</Text>
      </Card>
    </Container>
  );
};

export default HowItWorksPage;
