import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import { rem } from "@mantine/core";
import { CampaignStatus, ErrorResult } from "./gql/graphql";
import { TFunction } from "i18next";

const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;

export const showErrorNotification = (errorMessage: string) => {
  notifications.show({
    title: "Error",
    color: "red",
    message: errorMessage,
    icon: xIcon,
  });
};

export const showGraphQLErrorNotification = (result: ErrorResult) => {
  console.log(result);
  showErrorNotification(result.message);
};

export function formatCurrency(value: number) {
  const majorUnits = value / 100;
  try {
    // Note: if no `locale` is provided, the browser's default
    // locale will be used.
    return new Intl.NumberFormat("sk", {
      style: "currency",
      currency: "eur",
      minimumFractionDigits: 0,
    }).format(majorUnits);
  } catch (e: unknown) {
    // A fallback in case the NumberFormat fails for any reason
    return majorUnits.toFixed(2);
  }
}

export const getStatusColor = (status: CampaignStatus) => {
  switch (status) {
    case CampaignStatus.Active:
      return "green"; // Active campaigns are green
    case CampaignStatus.Ended:
      return "gray"; // Ended campaigns are gray
    case CampaignStatus.Scheduled:
      return "blue"; // Scheduled campaigns are blue
    case CampaignStatus.Cancelled:
      return "red"; // Cancelled campaigns are red
    default:
      return "gray"; // Default color for undefined states
  }
};

export const formatStatus = (status: CampaignStatus) => {
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
};

export const formatStatusLocalized = (status: CampaignStatus, t: TFunction) => {
  switch (status) {
    case CampaignStatus.Active:
      return t("campaign.status.active");
    case CampaignStatus.Ended:
      return t("campaign.status.ended");
    case CampaignStatus.Scheduled:
      return t("campaign.status.scheduled");
    case CampaignStatus.Cancelled:
      return t("campaign.status.cancelled");
    default:
      return t("campaign.status.unknown");
  }
};
