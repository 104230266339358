import { useQuery } from "@apollo/client";
import { Skeleton } from "@mantine/core";
import { activeCustomerQueryDocument } from "../../api/customer";
import MyInvestments from "./MyInvestments";

const MyInvestmentsContainer = () => {
  const { data, loading, error } = useQuery(activeCustomerQueryDocument);

  if (loading) {
    return <Skeleton height={200} />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <MyInvestments investments={data?.activeCustomer} />;
};

export default MyInvestmentsContainer;
