import { Routes as ReactRouterRoutes, Route } from "react-router-dom";
import HomePage from "../routes/HomePage";
import SignInPage from "../routes/SignInPage";
import SignUpPage from "../routes/SignUpPage";
import SignUpConfirmationPage from "../routes/SignUpConfirmationPage";
import VerifyUserPage from "../routes/VerifyUserPage";
import NotFoundPage from "../routes/NotFoundPage";
import ContactDetailsPage from "../routes/ContactDetailsPage";
import CardPaymentPage from "../routes/CardPaymentPage";
import InvestmentOptionsPage from "../routes/InvestmentOptionsPage";
import HowItWorksPage from "../routes/HowItWorksPage";
import AboutUsPage from "../routes/AboutUsPage";
import SellerSignUpPage from "../routes/SellerSignUpPage";
import { PageRoute } from "./PageRoute";
import MyInvestmentsPage from "../routes/MyInvestmentsPage";
import ProfilePage from "../routes/ProfilePage";
import VerifyUserPageWithPassword from "../routes/VerifyUserPageWithPassword";
import InvestmentConfirmationPage from "../routes/InvestmentConfirmationPage";
import CampaignDetailPage from "../routes/CampaignDetailPage";
import VerifySellerPage from "../routes/VerifySellerPage";
import PaymentMethodPage from "../routes/PaymentMethodPage";

const Routes = () => (
  <ReactRouterRoutes>
    <Route path={PageRoute.HOME} element={<HomePage />} />
    <Route path={PageRoute.CAMPAIGN_DETAIL} element={<CampaignDetailPage />} />
    <Route path={PageRoute.SIGN_IN} element={<SignInPage />} />
    <Route path={PageRoute.SIGN_UP} element={<SignUpPage />} />
    <Route path={PageRoute.SELLER_SIGN_UP} element={<SellerSignUpPage />} />
    <Route
      path={PageRoute.SIGN_UP_CONFIRMATION}
      element={<SignUpConfirmationPage />}
    />
    <Route path={PageRoute.VERIFY_USER} element={<VerifyUserPage />} />
    <Route
      path={PageRoute.VERIFY_USER_WITH_PASSWORD}
      element={<VerifyUserPageWithPassword />}
    />
    <Route path={PageRoute.VERIFY_SELLER} element={<VerifySellerPage />} />
    <Route path={PageRoute.CONTACT_DETAILS} element={<ContactDetailsPage />} />
    <Route path={PageRoute.PAYMENT_METHOD} element={<PaymentMethodPage />} />
    <Route path={PageRoute.CARD_PAYMENT} element={<CardPaymentPage />} />
    <Route
      path={PageRoute.INVESTMENT_CONFIRMATION}
      element={<InvestmentConfirmationPage />}
    />
    <Route
      path={PageRoute.INVESTMENT_OPTIONS}
      element={<InvestmentOptionsPage />}
    />
    <Route path={PageRoute.HOW_IT_WORKS} element={<HowItWorksPage />} />
    <Route path={PageRoute.ABOUT_US} element={<AboutUsPage />} />
    <Route path={PageRoute.MY_INVESTMENTS} element={<MyInvestmentsPage />} />
    <Route path={PageRoute.PROFILE} element={<ProfilePage />} />
    <Route path={PageRoute.NOT_FOUND} element={<NotFoundPage />} />
  </ReactRouterRoutes>
);

export default Routes;
