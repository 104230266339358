import CheckoutSummary from "./CheckoutSummary";
import { useQuery } from "@apollo/client";
import { activeOrderQueryDocument } from "../../api/order";
import { Skeleton } from "@mantine/core";
import { campaignQueryDocument } from "../../api/campaign";

const CheckoutSummaryContainer = () => {
  const { loading, error, data } = useQuery(activeOrderQueryDocument);
  const campaignQuery = useQuery(campaignQueryDocument, {
    variables: {
      id: data?.activeOrder?.customFields?.campaignId ?? "",
    },
    skip: !data?.activeOrder?.customFields?.campaignId,
  });

  if (loading || campaignQuery.loading) return <Skeleton height={300} />;

  if (error || campaignQuery.error) return <div>Error</div>;

  return (
    <CheckoutSummary
      investment={data?.activeOrder}
      campaign={campaignQuery.data?.campaign}
    />
  );
};

export default CheckoutSummaryContainer;
