import { Container, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CheckoutStep } from "../components/CheckoutStepper/CheckoutStep";
import CheckoutStepper from "../components/CheckoutStepper/CheckoutStepper";
import InvestmentConfirmationContainer from "../components/InvestmentConfirmation/InvestmentConfirmation.container";
import classes from "./SignInPage.module.css";

const InvestmentConfirmationPage = () => {
  const { t } = useTranslation();
  const { orderCode } = useParams();
  return (
    <Container size={900} my={40}>
      <CheckoutStepper active={CheckoutStep.SUMMARY} />
      <Container size={500} my={40}>
        <Title ta="center" className={classes.title}>
          {t("investmentConfirmationTitle")}
        </Title>
        <InvestmentConfirmationContainer orderCode={orderCode ?? ""} />
      </Container>
    </Container>
  );
};

export default InvestmentConfirmationPage;
