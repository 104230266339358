import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/charts/styles.css";
import "./style.css";
import {
  MantineColorsTuple,
  MantineProvider,
  createTheme,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppShell from "../components/AppShell/AppShell";
import ScrollToAnchor from "../components/ScrollToAnchor";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apolloClient";

const myColor: MantineColorsTuple = [
  "#e7fcf2",
  "#daf1e5",
  "#b8e1cb",
  "#94ceb0",
  "#75bf98",
  "#61b689",
  "#55b181",
  "#449b6e",
  "#398b61",
  "#287951",
];

const theme = createTheme({
  primaryColor: "myColor",
  colors: {
    myColor,
  },
  cursorType: "pointer",
});

const queryClient = new QueryClient();

const App = () => (
  <ApolloProvider client={client}>
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <Notifications />
        <AppShell />
        {/* <ReactQueryDevtools initialIsOpen={true} client={queryClient} /> */}
        <ScrollToAnchor />
      </MantineProvider>
    </QueryClientProvider>
  </ApolloProvider>
);

export default App;
