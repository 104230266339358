import {
  Anchor,
  Button,
  Group,
  Paper,
  PasswordInput,
  Stack,
  TextInput,
} from "@mantine/core";
import { FC } from "react";
import { useForm } from "@mantine/form";
import { RegisterSellerInput } from "../../gql/graphql";
import { useTranslation } from "react-i18next";

const extractRegistrationFormValues = (
  formValues: SellerSignUpFormValues
): RegisterSellerInput => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { repeatPassword, shopName, ...input } = formValues;
  return { shopName: formValues.shopName, seller: input };
};

type SellerSignUpFormValues = {
  shopName: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  password: string;
  repeatPassword: string;
};

type Props = {
  isLoading: boolean;
  onSubmit: (values: RegisterSellerInput) => void;
};

const SellerSignUpForm: FC<Props> = ({ isLoading, onSubmit }) => {
  const { t } = useTranslation();

  const form = useForm<SellerSignUpFormValues>({
    validate: {
      emailAddress: (value) =>
        /^\S+@\S+$/.test(value) ? null : t("sellerSignUpPage.invalidEmail"),
      password: (value) =>
        value.length >= 4 ? null : t("sellerSignUpPage.shortPassword"),
      repeatPassword: (value, values) =>
        value === values.password
          ? null
          : t("sellerSignUpPage.passwordsDoNotMatch"),
    },
  });

  const handleSubmit = (values: SellerSignUpFormValues) => {
    onSubmit(extractRegistrationFormValues(values));
  };

  return (
    <Paper withBorder shadow="md" p={30} mt={30} radius="md">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="sm">
          <TextInput
            label={t("sellerSignUp.companyName")}
            placeholder={t("sellerSignUp.companyNamePlaceholder")}
            required
            data-testid="shopName"
            {...form.getInputProps("shopName")}
          />
          <TextInput
            label={t("sellerSignUp.email")}
            placeholder={t("sellerSignUp.yourEmail")}
            required
            data-testid="email"
            {...form.getInputProps("emailAddress")}
          />
          <TextInput
            label={t("sellerSignUp.firstName")}
            placeholder={t("sellerSignUp.firstName")}
            required
            data-testid="firstName"
            {...form.getInputProps("firstName")}
          />
          <TextInput
            label={t("sellerSignUp.lastName")}
            placeholder={t("sellerSignUp.lastName")}
            required
            data-testid="lastName"
            {...form.getInputProps("lastName")}
          />
          <PasswordInput
            label={t("sellerSignUp.password")}
            placeholder={t("sellerSignUp.enterPassword")}
            required
            data-testid="password"
            {...form.getInputProps("password")}
          />
          <PasswordInput
            label={t("sellerSignUp.repeatPassword")}
            placeholder={t("sellerSignUp.repeatPasswordPlaceholder")}
            required
            data-testid="repeatPassword"
            {...form.getInputProps("repeatPassword")}
          />
        </Stack>
        <Group justify="space-between" mt="xs">
          <Anchor component="button" size="sm">
            {t("sellerSignUp.forgotPassword")}
          </Anchor>
        </Group>
        <Button
          type="submit"
          fullWidth
          mt="lg"
          loading={isLoading}
          data-testid="signUpBtn"
        >
          {t("sellerSignUp.createAccountButton")}
        </Button>
      </form>
    </Paper>
  );
};

export default SellerSignUpForm;
