import { FC } from "react";
import { Stepper } from "@mantine/core";
import { CheckoutStep } from "./CheckoutStep";
import { useTranslation } from "react-i18next";

type Props = {
  active: CheckoutStep;
  customPaymentStepDescription?: string;
};

const CheckoutStepper: FC<Props> = ({
  active,
  customPaymentStepDescription,
}) => {
  const { t } = useTranslation();
  return (
    <Stepper active={active} mb="xl">
      <Stepper.Step
        label={t("checkout.start")}
        description={t("checkout.startDescription")}
      ></Stepper.Step>
      <Stepper.Step
        label={t("checkout.contactDetails")}
        description={t("checkout.contactDetailsDescription")}
      ></Stepper.Step>
      <Stepper.Step
        label={t("checkout.payment")}
        description={
          customPaymentStepDescription ?? t("checkout.paymentDescription")
        }
      ></Stepper.Step>
      <Stepper.Step
        label={t("checkout.summary")}
        description={t("checkout.summaryDescription")}
      ></Stepper.Step>
      <Stepper.Completed>
        <></>
      </Stepper.Completed>
    </Stepper>
  );
};

export default CheckoutStepper;
