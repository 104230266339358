export enum PageRoute {
  HOME = "/",
  CAMPAIGN_DETAIL = "/campaign-detail/:id",
  SIGN_IN = "/sign-in",
  SIGN_UP = "/sign-up",
  SELLER_SIGN_UP = "/seller-sign-up",
  SIGN_UP_CONFIRMATION = "/sign-up-confirmation",
  VERIFY_USER = "/verify-user",
  VERIFY_USER_WITH_PASSWORD = "/verify-user-with-password",
  VERIFY_SELLER = "/verify-seller",
  CONTACT_DETAILS = "/contact-details",
  PAYMENT_METHOD = "/payment-method",
  CARD_PAYMENT = "/card-payment",
  INVESTMENT_CONFIRMATION = "/investment-confirmation/:orderCode",
  INVESTMENT_OPTIONS = "/investment-options",
  HOW_IT_WORKS = "/how-it-works",
  ABOUT_US = "/about-us",
  MY_INVESTMENTS = "/my-investments",
  PROFILE = "/profile",
  NOT_FOUND = "*",
}
