import { graphql } from "../gql";

export const activeCustomerQueryDocument = graphql(`
  query activeCustomer {
    activeCustomer {
      id
      firstName
      lastName
      emailAddress
      orders {
        items {
          id
          code
          createdAt
          state
          customFields {
            campaignId
          }
          payments {
            amount
            method
            metadata
            state
          }
          fulfillments {
            id
            createdAt
            state
            customFields {
              downloadUrls
            }
          }
          lines {
            id
            quantity
            linePriceWithTax
            productVariant {
              product {
                name
                description
                featuredAsset {
                  preview
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const setCustomerForOrderMutationDocument = graphql(`
  mutation SetCustomerForOrder($input: CreateCustomerInput!) {
    setCustomerForOrder(input: $input) {
      ...ActiveOrder
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`);

export const signOutMutationDocument = graphql(`
  mutation LogOut {
    logout {
      success
    }
  }
`);

export const signUpMutationDocument = graphql(`
  mutation Register($input: RegisterCustomerInput!) {
    registerCustomerAccount(input: $input) {
      __typename
      ... on Success {
        success
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`);

export const signInMutationDocument = graphql(`
  mutation LogIn(
    $emailAddress: String!
    $password: String!
    $rememberMe: Boolean!
  ) {
    login(
      username: $emailAddress
      password: $password
      rememberMe: $rememberMe
    ) {
      __typename
      ... on CurrentUser {
        id
        identifier
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`);

export const verifyUserMutationDocument = graphql(`
  mutation Verify($token: String!, $password: String) {
    verifyCustomerAccount(token: $token, password: $password) {
      __typename
      ... on CurrentUser {
        id
        identifier
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`);
