import { Container, SimpleGrid } from "@mantine/core";
import PaymentMethodFormContainer from "../components/PaymentMethodForm/PaymentMethodForm.container";
import CheckoutSummaryContainer from "../components/CheckoutSummary/CheckoutSummary.container";
import CheckoutStepper from "../components/CheckoutStepper/CheckoutStepper";
import { CheckoutStep } from "../components/CheckoutStepper/CheckoutStep";

const PaymentMethodPage = () => (
  <Container size={900} my={40}>
    <CheckoutStepper active={CheckoutStep.PAYMENT} />
    <Container size={800} my={40}>
      <SimpleGrid
        cols={{
          base: 1,
          sm: 2,
        }}
        spacing="xl"
        mt={48}
      >
        <PaymentMethodFormContainer />
        <CheckoutSummaryContainer />
      </SimpleGrid>
    </Container>
  </Container>
);

export default PaymentMethodPage;
