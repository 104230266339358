import { graphql } from "../gql";

export const setContactDetailsMutationDocument = graphql(`
  mutation SetOrderShippingAddress($input: CreateAddressInput!) {
    setOrderShippingAddress(input: $input) {
      __typename
      ... on Order {
        id
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`);

export const setShippingMethodMutationDocument = graphql(`
  mutation SetShippingMethod($id: [ID!]!) {
    setOrderShippingMethod(shippingMethodId: $id) {
      ...ActiveOrder
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`);

export const elligibleShippingMethodsQueryDocument = graphql(`
  query EligibleShippingMethods {
    eligibleShippingMethods {
      id
      code
      name
    }
  }
`);

export const setOrderCustomFieldsMutationDocument = graphql(`
  mutation SetOrderCustomFields($input: UpdateOrderInput!) {
    setOrderCustomFields(input: $input) {
      ...ActiveOrder
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`);

export const orderQueryDocument = graphql(`
  query GetOrderByCode($code: String!) {
    orderByCode(code: $code) {
      id
      code
      state
      lines {
        id
        unitPriceWithTax
        quantity
        linePriceWithTax
        productVariant {
          id
          name
          sku
          product {
            name
            customFields {
              ratio
            }
          }
        }
        featuredAsset {
          id
          preview
        }
      }
      totalWithTax
      customFields {
        campaignId
      }
    }
  }
`);

export const addPaymentToOrderMutationDocument = graphql(`
  mutation AddPaymentToOrder($input: PaymentInput!) {
    addPaymentToOrder(input: $input) {
      ... on Order {
        code
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`);

export const transitionToStateMutationDocument = graphql(`
  mutation TransitionToState($state: String!) {
    transitionOrderToState(state: $state) {
      ...ActiveOrder
      ... on OrderStateTransitionError {
        errorCode
        message
        transitionError
        fromState
        toState
      }
    }
  }
`);

export const activeOrderQueryDocument = graphql(`
  query GetActiveOrder {
    activeOrder {
      code
      state
      lines {
        id
        unitPriceWithTax
        quantity
        linePriceWithTax
        productVariant {
          id
          name
          sku
          product {
            name
            customFields {
              ratio
            }
          }
        }
        featuredAsset {
          id
          preview
        }
      }
      totalWithTax
      customFields {
        campaignId
      }
    }
  }
`);

export const createPaymentIntentMutationDocument = graphql(`
  mutation CreatePaymentIntent {
    createStripePaymentIntent
  }
`);
