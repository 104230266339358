import SellerSignUpForm from "./SellerSignUpForm";
import { RegisterSellerInput } from "../../gql/graphql";
import { useNavigate } from "react-router-dom";
import { showErrorNotification } from "../../utils";
import { useMutation } from "@apollo/client";
import { signUpMutationDocument } from "../../api/seller";
import { PageRoute } from "../PageRoute";

const SellerSignInFormContainer = () => {
  const navigate = useNavigate();

  const [signUp, { loading }] = useMutation(signUpMutationDocument, {
    onError: (error) => {
      showErrorNotification(error.message);
    },
  });

  const onSubmit = async (values: RegisterSellerInput) => {
    const res = await signUp({ variables: { input: values } });
    if (res.data?.registerNewSeller) {
      navigate(PageRoute.SIGN_UP_CONFIRMATION, {
        state: { email: values.seller.emailAddress, isAdmin: true },
      });
    }
  };

  return <SellerSignUpForm onSubmit={onSubmit} isLoading={loading} />;
};

export default SellerSignInFormContainer;
