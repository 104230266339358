import { useQuery } from "@apollo/client";
import { Group, Avatar, Text } from "@mantine/core";
import { activeCustomerQueryDocument } from "../../api/customer";

const UserButton = () => {
  const activeCustomer = useQuery(activeCustomerQueryDocument);

  return (
    <Group>
      <Avatar radius="xl" size="lg" />

      <div style={{ flex: 1 }}>
        <Text size="xl" fw={500}>
          {activeCustomer.data?.activeCustomer?.firstName}{" "}
          {activeCustomer.data?.activeCustomer?.lastName}
        </Text>

        <Text c="dimmed" size="sm">
          {activeCustomer.data?.activeCustomer?.emailAddress}
        </Text>
      </div>
    </Group>
  );
};

export default UserButton;
