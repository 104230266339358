import { FC } from "react";
import { Skeleton } from "@mantine/core";
import { allProductsQueryDocument } from "../../api/product";
import { campaignQueryDocument } from "../../api/campaign";
import { useQuery } from "@apollo/client";
import CampaignDetail from "./CampaignDetail";

type Props = {
  id: string;
};

const CampaignDetailContainer: FC<Props> = ({ id }) => {
  const campaignQuery = useQuery(campaignQueryDocument, {
    variables: { id },
  });
  const productsQuery = useQuery(allProductsQueryDocument, {
    variables: {
      options: {
        filter: {
          campaignId: { eq: id },
        },
      },
    },
  });

  if (campaignQuery.data && productsQuery.data) {
    return (
      <CampaignDetail
        campaign={campaignQuery.data.campaign}
        products={productsQuery.data.products}
      />
    );
  }

  if (campaignQuery.error || productsQuery.error) {
    return <div>Error</div>;
  }

  return <Skeleton height={1000} />;
};

export default CampaignDetailContainer;
