import {
  Text,
  Anchor,
  Button,
  Checkbox,
  Group,
  Paper,
  PasswordInput,
  TextInput,
} from "@mantine/core";
import { FC } from "react";
import { useForm } from "@mantine/form";
import { LogInMutationVariables } from "../../gql/graphql";
import { useTranslation } from "react-i18next";

type Props = {
  isLoading: boolean;
  isPasswordIncorrect: boolean;
  onSubmit: (values: LogInMutationVariables) => void;
};

const SignInForm: FC<Props> = ({
  isLoading,
  isPasswordIncorrect,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const form = useForm<LogInMutationVariables>({
    initialValues: {
      emailAddress: "",
      password: "",
      rememberMe: false,
    },
    validate: {
      emailAddress: (value) =>
        /^\S+@\S+$/.test(value) ? null : t("signIn.invalidEmail"),
      password: (value) =>
        value.length >= 4 ? null : t("signIn.shortPassword"),
    },
  });

  return (
    <Paper withBorder shadow="md" p="xl" mt="xl" radius="md">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          label={t("signIn.email")}
          placeholder={t("signIn.yourEmail")}
          required
          data-testid="email"
          {...form.getInputProps("emailAddress")}
        />
        <PasswordInput
          label={t("signIn.password")}
          placeholder={t("signIn.yourPassword")}
          required
          mt="md"
          data-testid="password"
          {...form.getInputProps("password")}
        />
        {isPasswordIncorrect && (
          <Text c="red" size="sm" mt="xs">
            {t("signIn.incorrectPassword")}
          </Text>
        )}
        <Group justify="space-between" mt="lg">
          <Checkbox
            label={t("signIn.rememberLogin")}
            {...form.getInputProps("rememberMe", { type: "checkbox" })}
          />
          <Anchor component="button" size="sm">
            {t("signIn.forgotPassword")}
          </Anchor>
        </Group>
        <Button
          type="submit"
          fullWidth
          mt="xl"
          loading={isLoading}
          data-testid="sign-in-btn"
        >
          {t("signIn.signIn")}
        </Button>
      </form>
    </Paper>
  );
};

export default SignInForm;
