import { Container, Title, Text, Anchor } from "@mantine/core";
import classes from "./SignInPage.module.css";
import SignUpFormContainer from "../components/SignUpForm/SignUpForm.container";
import { Link } from "react-router-dom";
import { PageRoute } from "../components/PageRoute";
import { useTranslation } from "react-i18next";

const SignUpPage = () => {
  const { t } = useTranslation();

  return (
    <Container size={420} my={40}>
      <Title ta="center" className={classes.title}>
        {t("signUp.createAccount")}
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        {t("signUp.alreadyHaveAccount")}{" "}
        <Anchor component={Link} to={PageRoute.SIGN_IN} size="sm">
          {t("signUp.signIn")}
        </Anchor>
      </Text>
      <SignUpFormContainer />
    </Container>
  );
};

export default SignUpPage;
