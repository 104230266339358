import { Group, NavLink, Stack } from "@mantine/core";
import {
  IconChartAreaLine,
  IconChevronRight,
  IconUser,
} from "@tabler/icons-react";
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { PageRoute } from "../PageRoute";
import UserButton from "../UserButton/UserButton";
import { useTranslation } from "react-i18next";

type Props = {
  children?: React.ReactNode;
};

const UserNavbar: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const data = [
    {
      icon: IconUser,
      label: t("profile"),
      to: PageRoute.PROFILE,
      rightSection: <IconChevronRight size="1rem" stroke={1.5} />,
    },
    {
      icon: IconChartAreaLine,
      label: t("myInvestments"),
      to: PageRoute.MY_INVESTMENTS,
      rightSection: <IconChevronRight size="1rem" stroke={1.5} />,
    },
  ];

  const items = data.map((item) => (
    <NavLink
      key={item.label}
      component={Link}
      to={item.to}
      active={location.pathname === item.to}
      label={item.label}
      rightSection={item.rightSection}
      leftSection={<item.icon size="1rem" stroke={1.5} />}
    />
  ));

  return (
    <Group gap={64} align="flex-start">
      <Stack w={300}>
        <UserButton />
        <Stack gap={0}>{items}</Stack>
      </Stack>

      <Group flex={1} wrap="nowrap">
        {children}
      </Group>
    </Group>
  );
};

export default UserNavbar;
