import { FC } from "react";
import InvestmentConfirmation from "./InvestmentConfirmation";
import { useQuery } from "@apollo/client";
import { activeCustomerQueryDocument } from "../../api/customer";
import { Skeleton } from "@mantine/core";
import { orderQueryDocument } from "../../api/order";
import { campaignQueryDocument } from "../../api/campaign";
import { showErrorNotification } from "../../utils";
import { client } from "../../app/apolloClient";

type Props = {
  orderCode: string;
};

const InvestmentConfirmationContainer: FC<Props> = ({ orderCode }) => {
  const { loading, data } = useQuery(activeCustomerQueryDocument, {
    onError: (error) => {
      showErrorNotification(error.message);
    },
  });

  const orderQuery = useQuery(orderQueryDocument, {
    variables: {
      code: orderCode,
    },
    skip: !orderCode,
    onError: (error) => {
      showErrorNotification(error.message);
    },
  });

  const campaignQuery = useQuery(campaignQueryDocument, {
    variables: {
      id: orderQuery.data?.orderByCode?.customFields?.campaignId ?? "",
    },
    skip: !orderQuery.data?.orderByCode?.customFields?.campaignId,
    onError: (error) => {
      showErrorNotification(error.message);
    },
    onCompleted: () => {
      client.refetchQueries({ include: [activeCustomerQueryDocument] });
    },
  });

  console.log(orderQuery);

  if (loading || orderQuery.loading || campaignQuery.loading) {
    return <Skeleton height={300} />;
  }

  const isSignedIn = data?.activeCustomer !== null;

  return (
    <InvestmentConfirmation
      id={orderCode ?? "<order not found>"}
      isSignedIn={isSignedIn}
      campaign={campaignQuery.data?.campaign}
    />
  );
};

export default InvestmentConfirmationContainer;
