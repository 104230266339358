import { Text, Container, ActionIcon, Group, rem, Button } from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
} from "@tabler/icons-react";
import Logo from "../Logo";
import { Link } from "react-router-dom";
import { PageRoute } from "../PageRoute";
import { useTranslation } from "react-i18next";
import classes from "./Footer.module.css";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

const Footer = () => {
  const { t } = useTranslation();

  const data = [
    {
      links: [
        { label: t("navigation.projects"), link: "#campaigns" },
        { label: t("navigation.aboutUs"), link: PageRoute.ABOUT_US },
        { label: t("navigation.howItWorks"), link: PageRoute.HOW_IT_WORKS },
      ],
    },
  ];

  const groups = data.map((group, groupIndex) => {
    const links = group.links.map((link, index) => (
      <Text
        key={index}
        className={classes.link}
        component={Link}
        to={link.link}
      >
        {link.label}
      </Text>
    ));

    return (
      <div key={groupIndex} className={classes.wrapper}>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <Logo />
          <Text size="xs" c="dimmed" className={classes.description}>
            {t("footer.slogan")}
          </Text>
        </div>
        <div className={classes.groups}>
          {groups}
          <div className={classes.wrapper}>
            <Text className={classes.title}>{t("footer.howToSell")}</Text>
            <Button component={Link} to={PageRoute.SELLER_SIGN_UP} size="md">
              {t("footer.sellerSignUpBtn")}
            </Button>
          </div>
        </div>
      </Container>
      <Container className={classes.afterFooter}>
        <Text c="dimmed" size="sm">
          © 2024 crowd-farming.tech. {t("footer.allRightsReserved")}.
        </Text>

        <Group
          gap={0}
          className={classes.social}
          justify="flex-end"
          wrap="nowrap"
        >
          <LanguageSwitcher />
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandTwitter
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          </ActionIcon>
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandYoutube
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          </ActionIcon>
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandInstagram
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
};

export default Footer;
