import { FC } from "react";
import {
  Badge,
  Button,
  Card,
  Group,
  Image,
  Stack,
  Text,
  Timeline,
  Title,
} from "@mantine/core";
import { ProduceTable } from "../ProduceTable";
import { ActiveCustomerQuery, CampaignQuery } from "../../gql/graphql";
import { formatCurrency } from "../../utils";
import {
  IconCheck,
  IconClock,
  IconCoins,
  IconDownload,
  IconGitCommit,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow } from "date-fns";
import { enUS, sk } from "date-fns/locale";

type Props = {
  investment: NonNullable<
    ActiveCustomerQuery["activeCustomer"]
  >["orders"]["items"][0];
  campaign: CampaignQuery["campaign"];
};

const InvestmentCard: FC<Props> = ({ investment, campaign }) => {
  const { t, i18n } = useTranslation();

  const wireTransferPayment = investment.payments?.find(
    (payment) =>
      payment.method === "wire-transfer" && payment.state === "Authorized"
  );

  const determineState = (state: string) => {
    if (state === "PaymentAuthorized") {
      return t("waitingForPayment");
    }

    if (state === "PaymentSettled") {
      return t("paymentReceived");
    }

    if (state === "ProduceReady") {
      return t("produceReady");
    }
  };

  const stateStr = determineState(investment.state);

  const getActive = () => {
    if (investment.state === "PaymentAuthorized") {
      return 0;
    }
    if (investment.state === "PaymentSettled") {
      return 2;
    }
    if (investment.state === "ProduceReady") {
      return 3;
    }
  };

  const fulfillment = investment.fulfillments?.filter(
    (fulfillment) => fulfillment.state === "Pending"
  )[0];

  const downloadUrl = fulfillment?.customFields?.downloadUrls?.[0];

  const cardTestId = `investmentCard-${investment.code}`;

  console.log(wireTransferPayment);

  return (
    <Card
      shadow="sm"
      radius="md"
      padding="xl"
      withBorder
      style={{ flexDirection: "row" }}
      data-testid={cardTestId}
    >
      <Stack flex={1}>
        <Group justify="space-between" mt="md" mb="xs">
          <Title order={3} data-testid="investmentTitle">
            {t("investment")}
            {investment.id}
          </Title>
          <Badge size="lg">{stateStr}</Badge>
        </Group>
        <Group>
          <Group gap="md" mb={2}>
            <Title order={6}>{campaign?.seller.name}</Title>
          </Group>
        </Group>
        <ProduceTable productLines={investment.lines} />

        {wireTransferPayment && (
          <Stack gap="lg" mt="lg">
            <Title order={4}>{t("paymentInstructions")}</Title>
            <Group gap="xl" align="flex-start">
              <Image
                w={150}
                src={`data:image/png;base64,${wireTransferPayment.metadata.public.qrCode}`}
                data-testid="wireTransferQrCode"
              />
              <Image
                w={150}
                src={`data:image/png;base64,${wireTransferPayment.metadata.public.epcQrCode}`}
                data-testid="wireTransferEpcQrCode"
              />
              <Stack gap="xs">
                <Group align="center">
                  <Text fw={600} c="gray.6">
                    {t("amount")}:
                  </Text>
                  <Text data-testid="wireTransferAmount">
                    {formatCurrency(wireTransferPayment.amount)}
                  </Text>
                </Group>
                <Group align="center">
                  <Text fw={600} c="gray.6">
                    {t("accountNumber")}:
                  </Text>
                  <Text data-testid="wireTransferIban">
                    {wireTransferPayment.metadata.public.iban}
                  </Text>
                </Group>
                <Group align="center">
                  <Text fw={600} c="gray.6">
                    {t("variableSymbol")}:
                  </Text>
                  <Text data-testid="wireTransferVariableSymbol">
                    {wireTransferPayment.metadata.public.varSymbol}
                  </Text>
                </Group>
                <Group align="center">
                  <Text fw={600} c="gray.6">
                    {t("specificSymbol")}:
                  </Text>
                  <Text data-testid="wireTransferSpecificSymbol">
                    {wireTransferPayment.metadata.public.specSymbol}
                  </Text>
                </Group>
                <Group align="center">
                  <Text fw={600} c="gray.6">
                    {t("referenceSymbol")}:
                  </Text>
                  <Text data-testid="wireTransferReferenceSymbol">
                    {wireTransferPayment.metadata.public.referenceSymbol}
                  </Text>
                </Group>
              </Stack>
            </Group>
          </Stack>
        )}

        <Title order={4} mt="xl">
          {t("investmentTimeline")}
        </Title>

        <Timeline active={getActive()} bulletSize={24} lineWidth={2}>
          <Timeline.Item
            bullet={<IconCoins size={12} />}
            title={t("investmentCreated")}
          >
            <Text c="dimmed" size="sm">
              {t("newInvestmentMessage")}
            </Text>
            <Text size="xs" mt={4}>
              {formatDistanceToNow(new Date(investment.createdAt), {
                addSuffix: true,
                locale: i18n.language === "en" ? enUS : sk,
              })}
            </Text>
          </Timeline.Item>

          <Timeline.Item
            bullet={<IconGitCommit size={12} />}
            title={t("paymentReceivedTimeline")}
          >
            <Text c="dimmed" size="sm">
              {t("paymentProcessingMessage")}
            </Text>
            {/* <Text size="xs" mt={4}>
              52 minutes ago
            </Text> */}
          </Timeline.Item>

          <Timeline.Item
            title={t("waitingForHarvest")}
            bullet={<IconClock size={12} />}
            // lineVariant="dashed"
          >
            <Text c="dimmed" size="sm">
              {t("growingProduceMessage")}
            </Text>
            {/* <Text size="xs" mt={4}>
              34 minutes ago
            </Text> */}
          </Timeline.Item>

          <Timeline.Item
            title={t("produceReady")}
            bullet={<IconCheck size={12} />}
          >
            <Text c="dimmed" size="sm">
              {t("produceReadyMessage")}
            </Text>
            <Text size="xs" mt={4}>
              {fulfillment?.createdAt
                ? `${formatDistanceToNow(new Date(fulfillment.createdAt), { addSuffix: true, locale: i18n.language === "en" ? enUS : sk })}`
                : ""}
            </Text>
            {investment.state === "ProduceReady" && (
              <Button
                component="a"
                target="_blank"
                download
                href={downloadUrl}
                mt="md"
                variant="outline"
                leftSection={<IconDownload />}
              >
                {t("downloadCertificate")}
              </Button>
            )}
          </Timeline.Item>
        </Timeline>

        <Group justify="flex-end">
          <Button mt="md" radius="md">
            {t("viewDetails")}
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};

export default InvestmentCard;
