import { useParams } from "react-router-dom";
import { Container } from "@mantine/core";
import CampaignDetailContainer from "../components/CampaignDetail/CampaignDetail.container";

const CampaignDetailPage = () => {
  const { id } = useParams();
  return (
    <Container mt="xl">
      <CampaignDetailContainer id={id ?? ""} />
    </Container>
  );
};

export default CampaignDetailPage;
