import { Container } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { CheckoutStep } from "../components/CheckoutStepper/CheckoutStep";
import CheckoutStepper from "../components/CheckoutStepper/CheckoutStepper";
import InvestmentOptionsContainer from "../components/InvestmentOptions/InvestmentOptions.container";

const InvestmentOptionsPage = () => {
  const { state } = useLocation();
  return (
    <Container size={900} my={40}>
      <CheckoutStepper active={CheckoutStep.INVESTMENT} />
      <Container size="sm" my={40}>
        <InvestmentOptionsContainer
          investedAmount={Number(state.investedAmount)}
          investmentGoal={Number(state.investmentGoal)}
          campaignId={state.campaignId}
        />
      </Container>
    </Container>
  );
};

export default InvestmentOptionsPage;
