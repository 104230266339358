import {
  Text,
  Avatar,
  Group,
  Menu,
  UnstyledButton,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { FC, useState } from "react";
import cx from "clsx";
import {
  IconChevronDown,
  IconSettings,
  IconLogout,
  IconChartAreaLine,
} from "@tabler/icons-react";
import classes from "./ProfileButton.module.css";
import { ActiveCustomerQuery } from "../../gql/graphql";
import useSignOut from "../../hooks/useSignOut";
import { Link } from "react-router-dom";
import { PageRoute } from "../PageRoute";
import { useTranslation } from "react-i18next";

type Props = {
  user: NonNullable<ActiveCustomerQuery["activeCustomer"]>;
};

const ProfileButton: FC<Props> = ({ user }) => {
  const theme = useMantineTheme();

  const { t } = useTranslation();

  const [userMenuOpened, setUserMenuOpened] = useState(false);

  const fullName = `${user.firstName} ${user.lastName}`;

  const { signOut, loading } = useSignOut();

  const onSignOutClick = () => {
    signOut();
  };

  return (
    <Menu
      width={260}
      position="bottom-end"
      transitionProps={{ transition: "pop-top-right" }}
      onClose={() => setUserMenuOpened(false)}
      onOpen={() => setUserMenuOpened(true)}
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
        >
          <Group gap={7}>
            <Avatar alt={fullName} radius="xl" size={20} />
            <Text fw={500} size="sm" lh={1} mr={3}>
              {fullName}
            </Text>
            <IconChevronDown
              style={{ width: rem(12), height: rem(12) }}
              stroke={1.5}
            />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          component={Link}
          to={PageRoute.MY_INVESTMENTS}
          leftSection={
            <IconChartAreaLine
              style={{ width: rem(16), height: rem(16) }}
              color={theme.colors.green[6]}
              stroke={1.5}
            />
          }
        >
          {t("profileButton.myInvestments")}
        </Menu.Item>

        <Menu.Label>{t("profileButton.settings")}</Menu.Label>
        <Menu.Item
          component={Link}
          to={PageRoute.PROFILE}
          leftSection={
            <IconSettings
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          }
        >
          {t("profileButton.accountSettings")}
        </Menu.Item>
        <Menu.Item
          leftSection={
            <IconLogout
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          }
          onClick={onSignOutClick}
          disabled={loading}
        >
          {t("profileButton.signOut")}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default ProfileButton;
